import React, { useState } from 'react';
import './link_generator.css';
import LinkGenerator from './screens/Generate_links';
//import Preview from './screens/Preview';
import Direct from './screens/Direct';
import Preview from './screens/preview_final';
import { BrowserRouter as Router, Routes , Route} from "react-router-dom"
import Test from './screens/FileDownload';
import Stripe from './screens/stripe'
import Cancel from './screens/cancel'
import Success from './screens/success'
import Integration from "./screens/Integration";
import Re from "./screens/Re"

import "./fonts/BaskervvilleSC-Regular.ttf";
import "./fonts/Inter_18pt-Regular.ttf";
import "./fonts/Lato-Regular.ttf";
import "./fonts/Montserrat-Regular.ttf";
import "./fonts/Poppins-Regular.ttf";
import "./fonts/Roboto-Regular.ttf";
import "./fonts/SF-Pro.ttf";
import "./fonts/SFUIText-Regular.otf";



export default function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/Preview/:id" element={<Preview />}></Route> */}
        <Route path="/activate/:id" element={<Direct />}></Route>
        <Route path="/link_generate" element={<LinkGenerator />}></Route>
        <Route path="/connect/:index/hash/:uid" element={<Preview />}></Route>
        <Route path="/:file/:index/hash/:uid" element={<Test />}></Route>

        <Route path="/success" element={<Success />}></Route>
        <Route path="/:all/:uid" element={<Integration />}></Route>
        <Route path="/:name" element={<Re />}></Route>
      </Routes>
    </Router>
  );
}
