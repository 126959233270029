import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import bannerImage from "../images/banner.jpg";
import verify from "../images/Verify.png";
import Picture from "../components/picture";
import User from "../images/user.png";
import "./generate_links.css";
import "firebase/database";
import "../components/Firebase";
import firebase from "firebase/app";
import ReactPlayer from "react-player/youtube";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import VCard from "vcard-creator";
import { useSearchParams } from "react-router-dom";
import loadingVideo from "../Videos/loading_2.mp4"; 

library.add(faChevronDown, faChevronUp, faUserPlus);

function Preview() {
  const routeParams = useParams();
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [searchParams] = useSearchParams();
  const [pop_rank, setPop] = useState([]);
  const [tap_rank, setRank] = useState([]);
  const [connection, setConnection] = useState([]);
  const [alignment, setAlignment] = useState('center');
  
  
  const param1 = searchParams.get("q");
  var tap1 = 0;
  var pop1 = 0;
  const date = new Date();
  const [leadCapture, setLeadCapture] = useState(false);
  const [lead, setLead] = useState({
    inputFields: [], 
    extraFields: [], 
  });

  // Handle changes to inputFields and extraFields
  const handleInputChange = (type, index, newValue) => {
    if (type === "inputFields") {
      const updatedInputFields = [...lead.inputFields];
      updatedInputFields[index].name = newValue; // Update the name or value based on requirements
      setLead((prevLead) => ({ ...prevLead, inputFields: updatedInputFields }));
    } else if (type === "extraFields") {
      const updatedExtraFields = [...lead.extraFields];
      updatedExtraFields[index].value = newValue; // Update the value for extraFields
      setLead((prevLead) => ({ ...prevLead, extraFields: updatedExtraFields }));
    }
  };

  function generateToken(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let token = '';
      for (let i = 0; i < length; i++) {
        token += characters.charAt(Math.floor(Math.random() * characters.length));
      }
  
      return token;
    }

  // Handle form submission for capture leads
  const handleSubmitLead = async (e) => {
    e.preventDefault();
    sendLeadEmail(lead);
    const date1 = new Date();
    const months = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    let day = months[date1.getDay()];
    const date2 = day + ", " + date1.getDate() + "/" + (date1.getMonth() + 1);
    const contactDetails = {
      name: "",
      email: "",
      number: "",
      jobTitle: "",
      company: "",
      note: "",
      lead: true,
      card: accessoryCard,
      keychain: accessoryKeychain,
      button: accessoryButton,
      display: accessoryDisplay,
      share: accessoryShare,
      date: date2,
      index: generateToken(10),
    };

    lead.inputFields.forEach((field) => {
      switch (field.fieldType) {
        case "name":
          contactDetails.name = field.name;
          break;
        case "email":
          contactDetails.email = field.name;
          break;
        case "phone":
          contactDetails.number = field.name;
          break;
        case "jobtitle":
          contactDetails.jobTitle = field.name;
          break;
        case "Company":
          contactDetails.company = field.name;
          break;
        case "leadNote":
          contactDetails.note = field.name;
          break;
        default:
          break;
      }
    });

    const uid = routeParams.uid;

    firebase
      .database()
      .ref(`/users/${uid}/contact`)
      .once("value")
      .then((snapshot) => {
        // Step 2: Append the new value to the array
        const currentArray = snapshot.val() || []; // Initialize with an empty array if it doesn't exist

        currentArray.push(contactDetails);

        // Step 3: Update the array in the database
        firebase
          .database()
          .ref(`/users/${uid}/contact`)
          .set(currentArray)
          .then(() => {
            // Handle the success or navigate to another screen
            alert("Saved Successfully");
          })
          .catch((error) => {
            console.log("Error updating database:", error);
          });
      })
      .catch((error) => {
        // Handle errors if reading from the database fails
        console.log("Error reading database:", error);
      });
    setLeadCapture(false);
  };

  const findDataByIndexAndSort = (dataArray, targetIndex) => {
    // console.log(dataArray)
    let result = null; // Variable to store the matching object

    // Iterate over each entry in the dataArray
    dataArray.forEach((entry) => {
      // Iterate over the keys ('instagram', 'numbers', 'website', etc.)
      Object.keys(entry).forEach((key) => {
        const value = entry[key];

        // Only check arrays, skip other properties (e.g. 'name', 'address')
        if (Array.isArray(value)) {
          // Find the object inside the array that matches index and sort
          const matchingItem = value.find((obj) => obj.sort === targetIndex);
          // If a matching object is found, assign it to result
          if (matchingItem) {
            result = matchingItem;
          }
        }
      });
    });

    return result;
  };

  const connectMe = async () => {

    try {
      const card = { uid: routeParams.uid, index: routeParams.index };
      var splitcardIndex = [];
      const date1 = new Date();
      const months = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      let day = months[date1.getDay()];
      const date2 = day + ", " + date1.getDate() + "/" + (date1.getMonth() + 1);

      if (routeParams.index.includes("-")) {
        splitcardIndex = routeParams.index.split("-");
        card["index"] = splitcardIndex[0];
      }
      card["date"] = date2;
      card["favorite"] = "no";

      // Step 1: Read the existing connection data
      const snapshot = await firebase
        .database()
        .ref(`/users/${param1}/connection`)
        .once("value");

      // Step 2: Get the current array from the snapshot
      const currentArray = snapshot.val() || []; // Initialize with an empty array if it doesn't exist

      // Step 3: Check if the value already exists in the array
      const isValueExists = currentArray.some((item) => {
        return item.uid === card.uid;
      });
      if (!isValueExists) {
        // Step 4: Append the new value to the array
        currentArray.unshift(card);

        // Step 5: Update the array in the database
        await firebase
          .database()
          .ref(`/users/${param1}/connection`)
          .set(currentArray);

        // Step 6: Handle the success or navigate to another screen
        alert("Connected Successfully");
        sendLeadEmail({});
        // navigation.navigate('Contact');
      } else {
        // Handle the case where the value already exists
        alert("Contact Already Exists!");
      }
    } catch (error) {
      // Handle errors
      console.log("Error:", error);
    }
  };

  const handleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  useEffect(() => {
    score();
  }, [pop_rank, tap_rank, connection]);

  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const tap = async (item, title, name) => {
    var pop = {
      dates: [],
      counts: [],
    };

    var tapped = 0;

    const taps = item.map((itm) => {
      if (itm.value === title) {
        if (itm.tap) {
          itm.tap += 1;
        } else {
          itm.tap = tapped + 1;
        }
      }

      return itm;
    });

    tap1 += 1;
    const formattedDate = formatDate(date); // Default locale

    firebase
      .database()
      .ref(`/users/${routeParams.uid}/cards`)
      .once("value")
      .then((snapshot) => {
        const data = snapshot.val();
        const cards = data.map((itm) => {
          if (itm.index === routeParams.index) {
            itm[name] = taps;
          }

          return itm;
        });

        firebase
          .database()
          .ref(`/users/${routeParams.uid}`)
          .update({ cards: cards });
      });

    firebase
      .database()
      .ref(`/users/${routeParams.uid}`)
      .once("value")
      .then((snapshot) => {
        const data = snapshot.val();
        if (data.tap) {
          setRank(data.tap);
          if (data.tap.dates.includes(formattedDate)) {
            const index = data.tap.dates.indexOf(formattedDate);
            data.tap.counts[index] = data.tap.counts[index] + 1;
          } else {
            data.tap.dates.push(formattedDate);
            data.tap.counts.push(tap1);
          }

          firebase
            .database()
            .ref(`/users/${routeParams.uid}`)
            .update({ tap: data.tap });
        } else {
          pop.dates.push(formattedDate);
          pop.counts.push(tap1);

          firebase
            .database()
            .ref(`/users/${routeParams.uid}`)
            .update({ tap: pop });
        }
      });
  };

  const pop = () => {
    
    if (
      routeParams.index.includes("-card") ||
      routeParams.index.includes("-keychain") ||
      routeParams.index.includes("-button") ||
      routeParams.index.includes("-display")
    ) {
      var accessories_view = 0;
      accessories_view += 1;
      const cardIndex = routeParams.index.substring(
        0,
        routeParams.index.indexOf("-")
      );
      firebase
        .database()
        .ref(`/users/${routeParams.uid}/cards`)
        .once("value")
        .then((snapshot) => {
          const cards = snapshot.val(); 
          if (cards) {
            cards.forEach((card, index) => {
              if (card.index === cardIndex) {
                const cardKey = index;

                const currentAccessoriesView = card.accessories_view || 0; // Default to 0 if not present
                const newAccessoriesView =
                  currentAccessoriesView + accessories_view;

                firebase
                  .database()
                  .ref(`/users/${routeParams.uid}/cards/${cardKey}`)
                  .update({ accessories_view: newAccessoriesView })
                  .then(() => {
                    
                  })
                  .catch((error) => {
                    console.log("Error updating accessories view:", error);
                  });
              }
            });
          } else {
            console.log("No cards founds");
          }
          
        })
        .catch((error) => {
          console.log("Error retrieving cards",error);
        });
    } else {
      var pop = {
        dates: [],
        counts: [],
      };

      pop1 += 1;

      const formattedDate = formatDate(date);

      firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          if (data.pop) {
            setPop(data.pop);
            if (data.pop.dates.includes(formattedDate)) {
              const index = data.pop.dates.indexOf(formattedDate);
              data.pop.counts[index] = data.pop.counts[index] + 1;
            } else {
              data.pop.dates.push(formattedDate);
              data.pop.counts.push(pop1);
            }

            firebase
              .database()
              .ref(`/users/${routeParams.uid}`)
              .update({ pop: data.pop });
          } else {
            pop.dates.push(formattedDate);
            pop.counts.push(pop1);

            firebase
              .database()
              .ref(`/users/${routeParams.uid}`)
              .update({ pop: pop });
          }
        });
    }
  };

  const score = () => {
    var sum_tap = 0;
    var sum_pop = 0;
    var sum_connections = 0;
    var tap_through_rate = 0;
    var total_sum = 0;

    firebase
      .database()
      .ref(`/users/${routeParams.uid}`)
      .once("value")
      .then((snapshot) => {
        const data = snapshot.val();
        if (data.tap) {
          sum_tap = data.tap.counts.reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0);
        }
        if (data.pop) {
          sum_pop = data.pop.counts.reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0);
        }
        sum_connections = data.connection.length;

        tap_through_rate = sum_tap / sum_pop;

        total_sum = sum_tap + sum_pop + sum_connections;

        firebase
          .database()
          .ref(`/users/${routeParams.uid}`)
          .update({ score_insight: total_sum });
      });
  };

  const truncateTextAfterWords = (text, numWords) => {
    if (text) {
      if (text.length > numWords) {
        const truncatedText = text.slice(0, numWords);
        return truncatedText + "...";
      }
    }

    return text;
  };
  const [loading, setLoading] = useState(false);
  const [dataloading, setDataLoading]= useState(true)
  const [followUp, setFollowUp] = useState({});
  const [cropped, setCroppedImage] = useState("");
  const [color, setColor] = useState("");
  const [name, setName] = useState("");
  const [company, setComp] = useState("");
  const [job, setJob] = useState("");
  const [bio, setBio] = useState("");
  const [add, setAdd] = useState("");
  const [number, setNumber] = useState([]);
  const [address, setAddress] = useState([]);
  const [email, setEmail] = useState([]);

  const [em, setEm] = useState("");
  const [contactCard, setContactCard] = useState([]);
  const [whatsapp, setWhatsapp] = useState([]);
  const [facetime, setFacetime] = useState([]);
  const [call, setCall] = useState([]);
  const [wechat, setWechat] = useState([]);
  const [instagram, setInstagram] = useState([]);
  const [linkedin, setLinkedin] = useState([]);
  const [facebook, setFacebook] = useState([]);
  const [youtube, setYoutube] = useState([]);
  const [tiktok, setTiktok] = useState([]);
  const [twitter, setTwitter] = useState([]);
  const [snapchat, setSnapchat] = useState([]);
  const [clubhouse, setClubhouse] = useState([]);
  const [twitch, setTwitch] = useState([]);
  const [pinterest, setPinterest] = useState([]);
  const [website, setWebsite] = useState([]);
  const [calendly, setCalendly] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [etsy, setEtsy] = useState([]);
  const [appLink, setAppLink] = useState([]);
  const [chiliPiper, setChiliPiper] = useState([]);
  const [booksy, setBooksy] = useState([]);
  const [square, setSquare] = useState([]);
  const [yelp, setYelp] = useState([]);
  const [embeddedVideo, setEmbeddedVideo] = useState([]);
  const [zillow, setZillow] = useState([]);
  const [file, setFile] = useState([]);
  const [cash, setCash] = useState([]);
  const [venmo, setVenmo] = useState([]);
  const [zelle, setZelle] = useState([]);
  const [paypal, setPaypal] = useState([]);
  const [customLink, setCustomLink] = useState([]);
  const [linktree, setLinktree] = useState([]);
  const [discord, setDiscord] = useState([]);
  const [telegram, setTelegram] = useState([]);
  const [opensea, setOpensea] = useState([]);
  const [poshmark, setPoshmark] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const [hoobe, setHoobe] = useState([]);
  const [applemusic, setAppleMusic] = useState([]);
  const [spotify, setSpotify] = useState([]);
  const [soundcloud, setSoundcloud] = useState([]);
  const [header, setHeader] = useState([]);
  const [expandable, setExpandable] = useState([]);
  const [cover, setCover] = useState("");
  const [logo, setLogo] = useState("");
  const [trustpilot, setTrustpilot] = useState([]);
  const [membership, setMembership] = useState([]);
  const [userfont, setUserFont] = useState('');
  const [linkColor, setlinkColor] = useState("");
  const [accessoryCard, setAccessoryCard]= useState(false)
  const [accessoryKeychain, setAccessoryKeychain] = useState(false);
  const [accessoryButton, setAccessoryButton] = useState(false);
  const [accessoryDisplay, setAccessoryDisplay] = useState(false);
  const [accessoryShare, setAccessoryShare] = useState(false);
  const [qrLink, setQR] = useState("");
  
  
  const sendLeadEmail = async (lead) => {
    let leadEmail = "";
    let leadName = "";
    let ema = "";
    let leadPhone = "";
    let leadCompany = "";
    let leadJobTitle = "";

    console.log("param1: ", param1)

    if (Object.keys(lead).length <= 0) {
      try {
        // Fetch the user details from Firebase
        const snapshot = await firebase
          .database()
          .ref(`/users/${param1}`)
          .once("value");
        const data = snapshot.val();

        // Set leadName and leadEmail from Firebase
        leadName = data.name;
        leadEmail = data.email;
        ema = em + ", " + leadEmail;
        console.log("ema: ", ema);

      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    } else {
      // Loop through the input fields to dynamically assign values based on fieldType
      lead.inputFields?.forEach((field) => {
        if (field.fieldType === "email") {
          console.log("fieldemail:", field.name)
          ema = field.name;
        }
        if (field.fieldType === "name") {
          console.log("Fieldname", field.name)
          leadName = field.name;
        }
        if (field.fieldType === "phone") {
          leadPhone = field.name; // If you want to handle phone
        }
        if (field.fieldType === "Company") {
          leadCompany = field.name; // Handle Company field
        }
        if (field.fieldType === "jobtitle") {
          leadJobTitle = field.name; // Handle Job title field
        }
      });

      ema = em + ", " + ema; // Update ema with dynamic values
      console.log(
        "ema: ",
        ema,
      );
    }


    if (Object.keys(followUp).length >= 1) {
      let placeholders = {
        "{{Pop It Link}}": qrLink,
        "{{leadName}}": leadName || "{{leadName}}",
        "Lead's Name": leadName || "Lead's Name",
        "My Digital Business Card URL": qrLink,
        "Pop It User Name": followUp.name,
      };

      let popitlink = followUp.note;

      // Replace placeholders with actual values
      Object.keys(placeholders).forEach((key) => {
        popitlink = popitlink.replace(new RegExp(key, "g"), placeholders[key]);
      });

      let subject = followUp.heading;
      let subjectEmail = subject.includes("{{leadName}}")
        ? subject.replace("{{leadName}}", leadName || "{{leadName}}")
        : subject;

        console.log("Placeholder:", placeholders)


      const templateParams = {
        email: ema,
        subject: subjectEmail || "",
        message: popitlink || "",
        from_email: "connect@popitnl.nl",
        from_name: followUp.sender || "",
        sender_email: followUp.sender_email || "",
        cc: followUp.cc || "",
        bcc: followUp.bcc || "",
        content: followUp.attachment,
        link: qrLink,
        hasData: followUp.note.includes("My Digital Business Card URL")
          ? true
          : false,
      };
      console.log("Template: ", templateParams)

      // Send email via emailjs
      emailjs
        .send("service_09wrmij", "template_xvrdpvj", templateParams, {
          publicKey: "mRvHJl_6Jqke7byrY",
        })
        .then(() => {
          console.log("Email Sent!");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };


  const openMessage = (open, phoneNumber) => {
    window.location.replace(`${open}:${phoneNumber}`);
  };

  const [allData, setAllData] = useState([]);
  const imageMappings = {
    "message.png": require("../images/verify.jpg"),
    "message.png": require("../images/message.png"),
    "applemail.png": require("../images/applemail.png"),
    "facetime.png": require("../images/facetime.png"),
    "safari.png": require("../images/safari.png"),
    "whatsapp.png": require("../images/whatsapp.png"),
    "wechat.png": require("../images/wechat.png"),
    "address.png": require("../images/address.png"),
    "calendly.png": require("../images/calendly.png"),
    "facebook_logo.png": require("../images/facebook_logo.png"),
    "LinkedIn_logo_initials.webp": require("../images/LinkedIn_logo_initials.webp"),
    "X.png": require("../images/X.png"),
    "youtube.png": require("../images/youtube.png"),
    "instagram_nobg.png": require("../images/instagram_nobg.png"),
    "applecall.png": require("../images/applecall.png"),
    "snapchat.png": require("../images/snapchat.png"),
    "clubhouse.png": require("../images/clubhouse.png"),
    "twitch.webp": require("../images/twitch.webp"),
    "tiktok.png": require("../images/tiktok.png"),
    "pinterest.png": require("../images/pinterest.png"),
    "etsy.jpg": require("../images/etsy.jpg"),
    "venmo.webp": require("../images/venmo.webp"),
    "cashapp.png": require("../images/cashapp.png"),
    "appstore.png": require("../images/appstore.png"),
    "chilipiper.png": require("../images/chilipiper.png"),
    "booksy.jpg": require("../images/booksy.jpg"),
    "square.jpg": require("../images/square.jpg"),
    "spotify-removebg-preview.png": require("../images/spotify-removebg-preview.png"),
    "discord.png": require("../images/discord.png"),
    "customlink.png": require("../images/customlink.png"),
    "linktree.png": require("../images/linktree.png"),
    "applemusic.jpeg": require("../images/applemusic.jpeg"),
    "soundcloud.png": require("../images/soundcloud.png"),
    "hoobe.webp": require("../images/hoobe.webp"),
    "poshmark.webp": require("../images/poshmark.webp"),
    "telegram.png": require("../images/telegram.png"),
    "podcasts.png": require("../images/podcasts.png"),
    "paypal.jpg": require("../images/paypal.jpg"),
    "zelle.jpg": require("../images/zelle.jpg"),
    "zillow.png": require("../images/zillow.png"),
    "yelp.jpg": require("../images/yelp.jpg"),
    "contactcard.jpg": require("../images/contactcard.jpg"),
    "File.png": require("../images/File.png"),
    "google.png": require("../images/google.png"),
    "trustpilot.png": require("../images/trustpilot.png"),
    "opensea.webp": require("../images/opensea.webp"),
    "iphone_Call-removebg-preview.png": require("../images/applecall.png"),
  };

  const getImageSource = (imageName) => {
    const imageSource = imageMappings[imageName];
    return imageSource ? imageSource : null;
  };

  const sortData = () => {
    const proLinks = [
      facetime,
      linkedin,
      clubhouse,
      calendly,
      reviews,
      etsy,
      appLink,
      chiliPiper,
      booksy,
      square,
      yelp,
      embeddedVideo,
      file,
      zelle,
      customLink,
      telegram,
      opensea,
      podcasts,
      header,
      trustpilot,
      expandable,
    ];
    var arraysToPush = [
      number,
      email,
      address,
      contactCard,
      whatsapp,
      facetime,
      call,
      wechat,
      instagram,
      linkedin,
      facebook,
      youtube,
      tiktok,
      twitter,
      snapchat,
      clubhouse,
      twitch,
      pinterest,
      website,
      calendly,
      reviews,
      etsy,
      appLink,
      chiliPiper,
      booksy,
      square,
      yelp,
      embeddedVideo,
      zillow,
      file,
      cash,
      venmo,
      zelle,
      paypal,
      customLink,
      linktree,
      discord,
      telegram,
      opensea,
      poshmark,
      podcasts,
      hoobe,
      applemusic,
      spotify,
      soundcloud,
      header,
      expandable,
      trustpilot,
    ];

    let newData = [];

    arraysToPush.forEach(function (array) {
      if (array !== undefined && array !== null && array.length > 0) {
        array.forEach(function (value) {
          if (membership !== "normal") {
            newData.push(value);
          } else if (!proLinks.includes(array)) {
            newData.push(value);
          }
        });
      }
    });

    newData.sort((a, b) => a.sort - b.sort);
    setAllData(newData);
  };

  useEffect(() => {
    sortData();
  }, [cover]);

  const userData = () => {
    
    setDataLoading(true)
    if (routeParams.index.includes("-card")) {
      setAccessoryCard(true)
      const result = routeParams.index.substring(
        0,
        routeParams.index.indexOf("-card")
      );
  
      firebase
        .database()
        .ref(`/users/${routeParams.uid}/cards`)
        .once("value")
        .then((snapshot) => {
          const cards = snapshot.val();
          const card = cards.filter((data) => {
            return data.index === result;
          });      
          setFollowUp(card[0].followup ? card[0].followup : []);
          setQR(card[0].qr_link);
          setName(card[0].name);
          setJob(card[0].job);
          setComp(card[0].company);
          setBio(card[0].bio || "");
          setHeader(card[0].header);
          setCroppedImage(card[0].image);
          setAdd(card[0].address);
          setColor(card[0].color);
          setNumber(card[0].numbers);
          setEmail(card[0].emails);
          setContactCard(card[0].ContactCard);
          setFile(card[0].file);
          setLogo(card[0].logo);
          setWhatsapp(card[0].whatsapp);
          setFacetime(card[0].facetime);
          setHeader(card[0].header);
          setCall(card[0].call);
          setWechat(card[0].wechat);
          setAddress(card[0].addresses);
          setInstagram(card[0].instagram);
          setLinkedin(card[0].linkedin);
          setFacebook(card[0].facebook);
          setYoutube(card[0].youtube);
          setTiktok(card[0].tiktok);
          setTwitter(card[0].twitter);
          setSnapchat(card[0].snapchat);
          setClubhouse(card[0].clubhouse);
          setTwitch(card[0].twitch);
          setPinterest(card[0].pinterest);
          setWebsite(card[0].website);
          setCalendly(card[0].calendly);
          setReviews(card[0].reviews);
          setEtsy(card[0].etsy);
          setAppLink(card[0].applink);
          setChiliPiper(card[0].chilipiper);
          setBooksy(card[0].booksy);
          setSquare(card[0].square);
          setExpandable(card[0].expandable);
          setYelp(card[0].yelp);
          setEmbeddedVideo(card[0].embedded);
          setZillow(card[0].zillow);
          setCash(card[0].cash);
          setVenmo(card[0].venmo);
          setZelle(card[0].zelle);
          setPaypal(card[0].paypal);
          setCustomLink(card[0].customlink);
          setLinktree(card[0].linktree);
          setDiscord(card[0].discord);
          setTelegram(card[0].telegram);
          setOpensea(card[0].opensea);
          setPoshmark(card[0].poshmark);
          setPodcasts(card[0].podcasts);
          setHoobe(card[0].hoobe);
          setAppleMusic(card[0].applemusic);
          setSpotify(card[0].spotify);
          setSoundcloud(card[0].soundcloud);
          setTrustpilot(card[0].trustpilot);
          setUserFont(card[0].font);
          setlinkColor(card[0].linkColor);
          if (card[0].cover === "") {
            setCover(bannerImage);
          } else {
            setCover(card[0].cover);
          }
          
          setLeadCapture(card[0].lead);
          setLead(card[0].leadCapture);
          setAlignment(card[0].alignment ? card[0].alignment : "center");
          const item = findDataByIndexAndSort(card, 1);

          if (card[0].single) {
            singleLinks(item);
          }
          setDataLoading(false);

        });

      firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          setMembership(data.membership);
          setConnection(data.connection);
          setEm(data.email);
          setDataLoading(false);
        });

       
    } else if (routeParams.index.includes("-keychain")) {
    setAccessoryKeychain(true);
      const result = routeParams.index.substring(
        0,
        routeParams.index.indexOf("-keychain")
      );
      firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          setMembership(data.membership);
          setConnection(data.connection);
          setEm(data.email);
        });
      firebase
        .database()
        .ref(`/users/${routeParams.uid}/cards`)
        .once("value")
        .then((snapshot) => {
          const cards = snapshot.val();
          const card = cards.filter((data) => {
            return data.index === result;
          });
          setFollowUp(card[0].followup ? card[0].followup : []);
          setName(card[0].name);
          setJob(card[0].job);
          setLogo(card[0].logo);
          setComp(card[0].company);
          setHeader(card[0].header);
          setBio(card[0].bio || "");
          setCroppedImage(card[0].image);
          setColor(card[0].color);
          setNumber(card[0].numbers);
          setEmail(card[0].emails);
          setContactCard(card[0].ContactCard);
          setWhatsapp(card[0].whatsapp);
          setAdd(card[0].address);
          setQR(card[0].qr_link);
          setFile(card[0].file);
          setFacetime(card[0].facetime);
          setCall(card[0].call);
          setWechat(card[0].wechat);
          setAddress(card[0].addresses);
          setInstagram(card[0].instagram);
          setLinkedin(card[0].linkedin);
          setFacebook(card[0].facebook);
          setExpandable(card[0].expandable);
          setYoutube(card[0].youtube);
          setTiktok(card[0].tiktok);
          setTwitter(card[0].twitter);
          setSnapchat(card[0].snapchat);
          setClubhouse(card[0].clubhouse);
          setTwitch(card[0].twitch);
          setPinterest(card[0].pinterest);
          setWebsite(card[0].website);
          setCalendly(card[0].calendly);
          setReviews(card[0].reviews);
          setEtsy(card[0].etsy);
          setAppLink(card[0].applink);
          setChiliPiper(card[0].chilipiper);
          setBooksy(card[0].booksy);
          setSquare(card[0].square);
          setYelp(card[0].yelp);
          setEmbeddedVideo(card[0].embedded);
          setZillow(card[0].zillow);
          setCash(card[0].cash);
          setVenmo(card[0].venmo);
          setZelle(card[0].zelle);
          setPaypal(card[0].paypal);
          setCustomLink(card[0].customlink);
          setLinktree(card[0].linktree);
          setDiscord(card[0].discord);
          setTelegram(card[0].telegram);
          setOpensea(card[0].opensea);
          setPoshmark(card[0].poshmark);
          setPodcasts(card[0].podcasts);
          setHoobe(card[0].hoobe);
          setAppleMusic(card[0].applemusic);
          setSpotify(card[0].spotify);
          setSoundcloud(card[0].soundcloud);
          setTrustpilot(card[0].trustpilot);
          setUserFont(card[0].font);
          setlinkColor(card[0].linkColor);

          if (card[0].cover === "") {
            setCover(bannerImage);
          } else {
            setCover(card[0].cover);
          }
          setLeadCapture(card[0].lead);
          setLead(card[0].leadCapture);
          setAlignment(card[0].alignment ? card[0].alignment : "center");
          const item = findDataByIndexAndSort(card, 1);

          if (card[0].single) {
            singleLinks(item);
          }
          setDataLoading(false);
        });
    } else if (routeParams.index.includes("-button")) {
      setAccessoryButton(true);
      const result = routeParams.index.substring(
        0,
        routeParams.index.indexOf("-button")
      );
      firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          setMembership(data.membership);
          setConnection(data.connection);
          setEm(data.email);
        });
      firebase
        .database()
        .ref(`/users/${routeParams.uid}/cards`)
        .once("value")
        .then((snapshot) => {
          const cards = snapshot.val();
          const card = cards.filter((data) => {
            return data.index === result;
          });
          setFollowUp(card[0].followup ? card[0].followup : []);

          setName(card[0].name);
          setJob(card[0].job);
          setComp(card[0].company);
          setBio(card[0].bio || "");
          setCroppedImage(card[0].image);
          setAdd(card[0].address);
          setQR(card[0].qr_link);
          setColor(card[0].color);
          setLogo(card[0].logo);
          setNumber(card[0].numbers);
          setEmail(card[0].emails);
          setContactCard(card[0].ContactCard);
          setWhatsapp(card[0].whatsapp);
          setFile(card[0].file);
          setFacetime(card[0].facetime);
          setCall(card[0].call);
          setWechat(card[0].wechat);
          setHeader(card[0].header);
          setAddress(card[0].addresses);
          setInstagram(card[0].instagram);
          setExpandable(card[0].expandable);
          setLinkedin(card[0].linkedin);
          setFacebook(card[0].facebook);
          setYoutube(card[0].youtube);
          setTiktok(card[0].tiktok);
          setTwitter(card[0].twitter);
          setSnapchat(card[0].snapchat);
          setClubhouse(card[0].clubhouse);
          setTwitch(card[0].twitch);
          setPinterest(card[0].pinterest);
          setWebsite(card[0].website);
          setCalendly(card[0].calendly);
          setReviews(card[0].reviews);
          setEtsy(card[0].etsy);
          setAppLink(card[0].applink);
          setChiliPiper(card[0].chilipiper);
          setBooksy(card[0].booksy);
          setSquare(card[0].square);
          setYelp(card[0].yelp);
          setEmbeddedVideo(card[0].embedded);
          setZillow(card[0].zillow);
          setCash(card[0].cash);
          setVenmo(card[0].venmo);
          setZelle(card[0].zelle);
          setPaypal(card[0].paypal);
          setCustomLink(card[0].customlink);
          setLinktree(card[0].linktree);
          setDiscord(card[0].discord);
          setTelegram(card[0].telegram);
          setOpensea(card[0].opensea);
          setPoshmark(card[0].poshmark);
          setPodcasts(card[0].podcasts);
          setHoobe(card[0].hoobe);
          setAppleMusic(card[0].applemusic);
          setSpotify(card[0].spotify);
          setSoundcloud(card[0].soundcloud);
          setTrustpilot(card[0].trustpilot);
          setUserFont(card[0].font);
          setlinkColor(card[0].linkColor);

          if (card[0].cover === "") {
            setCover(bannerImage);
          } else {
            setCover(card[0].cover);
          }
          setLeadCapture(card[0].lead);
          setLead(card[0].leadCapture);
          setAlignment(card[0].alignment ? card[0].alignment : "center");
          const item = findDataByIndexAndSort(card, 1);

          if (card[0].single) {
            singleLinks(item);
          }
          setDataLoading(false);
        });
    } else if (routeParams.index.includes("-display")) {
    setAccessoryDisplay(true)
      const result = routeParams.index.substring(
        0,
        routeParams.index.indexOf("-display")
      );
      firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          setMembership(data.membership);
          setConnection(data.connection);
          setEm(data.email);
        });
      firebase
        .database()
        .ref(`/users/${routeParams.uid}/cards`)
        .once("value")
        .then((snapshot) => {
          const cards = snapshot.val();
          const card = cards.filter((data) => {
            return data.index === result;
          });
          setFollowUp(card[0].followup ? card[0].followup : []);
          setName(card[0].name);
          setJob(card[0].job);
          setComp(card[0].company);
          setBio(card[0].bio || "");
          setCroppedImage(card[0].image);
          setAdd(card[0].address);
          setQR(card[0].qr_link);
          setColor(card[0].color);
          setLogo(card[0].logo);
          setNumber(card[0].numbers);
          setEmail(card[0].emails);
          setContactCard(card[0].ContactCard);
          setWhatsapp(card[0].whatsapp);
          setFile(card[0].file);
          setFacetime(card[0].facetime);
          setCall(card[0].call);
          setWechat(card[0].wechat);
          setHeader(card[0].header);
          setAddress(card[0].addresses);
          setInstagram(card[0].instagram);
          setExpandable(card[0].expandable);
          setLinkedin(card[0].linkedin);
          setFacebook(card[0].facebook);
          setYoutube(card[0].youtube);
          setTiktok(card[0].tiktok);
          setTwitter(card[0].twitter);
          setSnapchat(card[0].snapchat);
          setClubhouse(card[0].clubhouse);
          setTwitch(card[0].twitch);
          setPinterest(card[0].pinterest);
          setWebsite(card[0].website);
          setCalendly(card[0].calendly);
          setReviews(card[0].reviews);
          setEtsy(card[0].etsy);
          setAppLink(card[0].applink);
          setChiliPiper(card[0].chilipiper);
          setBooksy(card[0].booksy);
          setSquare(card[0].square);
          setYelp(card[0].yelp);
          setEmbeddedVideo(card[0].embedded);
          setZillow(card[0].zillow);
          setCash(card[0].cash);
          setVenmo(card[0].venmo);
          setZelle(card[0].zelle);
          setPaypal(card[0].paypal);
          setCustomLink(card[0].customlink);
          setLinktree(card[0].linktree);
          setDiscord(card[0].discord);
          setTelegram(card[0].telegram);
          setOpensea(card[0].opensea);
          setPoshmark(card[0].poshmark);
          setPodcasts(card[0].podcasts);
          setHoobe(card[0].hoobe);
          setAppleMusic(card[0].applemusic);
          setSpotify(card[0].spotify);
          setSoundcloud(card[0].soundcloud);
          setTrustpilot(card[0].trustpilot);
          setUserFont(card[0].font);
          setlinkColor(card[0].linkColor);

          if (card[0].cover === "") {
            setCover(bannerImage);
          } else {
            setCover(card[0].cover);
          }
          setLeadCapture(card[0].lead);
          setLead(card[0].leadCapture);
          setAlignment(card[0].alignment ? card[0].alignment : "center");
          const item = findDataByIndexAndSort(card, 1);

          if (card[0].single) {
            singleLinks(item);
          }
          setDataLoading(false);
        });
    } else {
    setAccessoryShare(true);
      firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          setMembership(data.membership);
          setConnection(data.connection);
          setEm(data.email)
        });
      firebase
        .database()
        .ref(`/users/${routeParams.uid}/cards`)
        .once("value")
        .then((snapshot) => {
          const cards = snapshot.val();
          const card = cards.filter((data) => {
            return data.index === routeParams.index;
          });
          console.log("card: ", card)
          setName(card[0].name);
          setJob(card[0].job);
          setComp(card[0].company);
          setFollowUp(card[0].followup ? card[0].followup : [])
          setBio(card[0].bio || "");
          setAdd(card[0].address);
          setCroppedImage(card[0].image);
          setColor(card[0].color);
          setNumber(card[0].numbers);
          setEmail(card[0].emails);
          setLogo(card[0].logo);
          setContactCard(card[0].ContactCard);
          setWhatsapp(card[0].whatsapp);
          setFacetime(card[0].facetime);
          setCall(card[0].call);
          setWechat(card[0].wechat);
          setAddress(card[0].addresses);
          setInstagram(card[0].instagram);
          setHeader(card[0].header);
          setLinkedin(card[0].linkedin);
          setFile(card[0].file);
          setFacebook(card[0].facebook);
          setYoutube(card[0].youtube);
          setTiktok(card[0].tiktok);
          setTwitter(card[0].twitter);
          setSnapchat(card[0].snapchat);
          setClubhouse(card[0].clubhouse);
          setTwitch(card[0].twitch);
          setPinterest(card[0].pinterest);
          setWebsite(card[0].website);
          setCalendly(card[0].calendly);
          setReviews(card[0].reviews);
          setQR(card[0].qr_link)
          setEtsy(card[0].etsy);
          setExpandable(card[0].expandable);
          setAppLink(card[0].applink);
          setChiliPiper(card[0].chilipiper);
          setBooksy(card[0].booksy);
          setSquare(card[0].square);
          setYelp(card[0].yelp);
          setEmbeddedVideo(card[0].embedded);
          setZillow(card[0].zillow);
          setCash(card[0].cash);
          setVenmo(card[0].venmo);
          setZelle(card[0].zelle);
          setPaypal(card[0].paypal);
          setCustomLink(card[0].customlink);
          setLinktree(card[0].linktree);
          setDiscord(card[0].discord);
          setTelegram(card[0].telegram);
          setOpensea(card[0].opensea);
          setPoshmark(card[0].poshmark);
          setPodcasts(card[0].podcasts);
          setHoobe(card[0].hoobe);
          setAppleMusic(card[0].applemusic);
          setSpotify(card[0].spotify);
          setSoundcloud(card[0].soundcloud);
          setTrustpilot(card[0].trustpilot);
          setUserFont(card[0].font);
          setlinkColor(card[0].linkColor);
          if (card[0].cover === "") {
            console.log("in if")
            setCover(bannerImage);
          } else {
            console.log("in else")
            setCover(card[0].cover);
          }
          setLeadCapture(card[0].lead);
          setLead(card[0].leadCapture);
          setAlignment(card[0].alignment ? card[0].alignment : "center");
          const item = findDataByIndexAndSort(card, 1);
          if (card[0].single){ singleLinks(item);}
          
          setDataLoading(false);
        });
    }
  
  };

  const singleLinks = (item) =>{
      if (item.name === "Numbers") {
        openMessage("sms", item.value);
        
      } else if (item.name === "Whatsapp") {
        window.open(`whatsapp://send?phone=${item.value}`, "_self");
        
      } else if (item.name === "Facetime") {
        
        window.open(`facetime://tel:${item.value}`, "_self");
      } else if (item.name === "Wechat") {
        
        navigator.clipboard.writeText(item.value);
      } else if (item.name === "Linktree") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Twitch") {
        
        window.open("https://twitch.tv/" + item.value, "_self");
      } else if (item.name === "Zelle") {
        
        navigator.clipboard.writeText(item.value, "_self");
      } else if (item.name === "Zillow") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Cash") {
        
        window.open("https://cash.app/$" + item.value, "_self");
      } else if (item.name === "Venmo") {
        
        window.open("https://venmo.com/" + item.value, "_self");
      } else if (item.name === "Square") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Twitter") {
        
        window.open("https://twitter.com/" + item.value, "_self");
      } else if (item.name === "Clubhouse") {
        window.open("https://clubhouse.com/@" + item.value, "_self");
        
      } else if (item.name === "Calendly") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Soundcloud") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Customlink") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Paypal") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Yelp") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Spotify") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Opensea") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Telegram") {
        
        window.open("https://t.me/" + item.value, "_self");
      } else if (item.name === "Discord") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Applemusic") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Reviews") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Pinterest") {
        
        window.open("https://pinterest.com/" + item.value, "_self");
      } else if (item.name === "Hoobe") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Podcasts") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Poshmark") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Chili Piper") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Booksy") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Etsy") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Applink") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Facebook") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Linkedin") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Youtube") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Website") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Emails") {
        
        openMessage("mailto", item.value);
      } else if (item.name === "File") {
        
        window.open(item.value, "_self");
      } else if (item.name === "Call") {
        
        openMessage("tel", item.value);
      } else if (item.name === "Instagram") {
        
        window.open(`https://www.instagram.com/${item.value}`, "_self");
      } else if (item.name === "Tiktok") {
        
        window.open(`https://www.tiktok.com/@${item.value}`, "_self");
      } else if (item.name === "Snapchat") {
        window.open("https://snapchat.com/add/" + item.value, "_self");
        
      } else if (item.name === "Addresses") {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${item.value}`,
          "_self"
        );
        
      } else if (item.name === "Contact") {
        contact_card();
      } else if (item.name === "Trustpilot") {
        window.open(item.value, "_self");
        
      }
    
  }

  var contactlinks = [
    "numbers",
    "emails",
    "addresses",
    // "ContactCard",
    "whatsapp",
    "facetime",
    "call",
    "wechat",
    "instagram",
    "linkedin",
    "facebook",
    "youtube",
    "tiktok",
    "twitter",
    "snapchat",
    "clubhouse",
    "twitch",
    "pinterest",
    "website",
    "calendly",
    "reviews",
    "etsy",
    "applink",
    "chilipiper",
    "booksy",
    "square",
    "yelp",
    "embedded",
    "zillow",
    "file",
    "cash",
    "venmo",
    "zelle",
    "paypal",
    "customlink",
    "linktree",
    "discord",
    "telegram",
    "opensea",
    "poshmark",
    "podcasts",
    "hoobe",
    "applemusic",
    "spotify",
    "soundcloud",
    "header",
    "trustpilot",
    // "expandable",
  ];

  const save = () => {
    var middle_name= '';
    setLoading(true);
    const os = detectOS();
    console.log("OS", os);

    if (os === "iOS") {
      console.log("IN ios");
      var vCardData = new VCard();

      firebase
        .database()
        .ref(`users/${routeParams.uid}/cards`)
        .once("value")
        .then(async (snapshot) => {
          var splitcardIndex = [];
          const cards = snapshot.val();
          if (routeParams.index.includes("-")) {
            splitcardIndex = routeParams.index.split("-");
          }

          const contact = cards.filter((data) => {
            if (splitcardIndex.length > 0) {
              return data.index === splitcardIndex[0];
            } else {
              return data.index === routeParams.index;
            }
          });
          // console.log("qP:", queryParams.file);
          // console.log("ssss: ", contact[0].image);

          // console.log("ABCCC: ", contact[0].website);
          // const imageBase64 = contact[0]["image"].toString("base64");

          const res = await axios.get("https://backend.popitapp.nl/image", {
            params: {
              url: contact[0].image,
            },
          });
          // const name1 = contact[0].name.includes(" ")
          //   ? contact[0].name.split(" ")
          //   : contact[0].name;
          // const first_name =
          //   typeof name1 === "string" ? contact[0].name : name1[0];
          // const last_name =
          //   typeof name1 === "string" ? "" : name1[name1.length - 1];
          // if (name1.length > 2) {
          //   middle_name = typeof name1 === "string" ? "" : name1[1];
          // }

          const name1 = contact[0].name.includes(" ")
            ? contact[0].name.split(" ")
            : [contact[0].name];

          const first_name = name1[0] || "";
          const last_name = name1.length > 1 ? name1[name1.length - 1] : "";
          const middle_name = name1.length === 3 ? name1[1] : ""; 

          vCardData
            .addName(last_name, first_name, middle_name) // before we had || "No Name" as well
            .addCompany(contact[0].company || "No Company")
            .addJobtitle(contact[0].job || "No Job")
            .addRole(contact[0].job || "No Role")
            .addNote(contact[0].bio || "No Bio")
            .addPhoto(res.data.img, res.data.mime);

          for (let j = 0; j < contactlinks.length; j++) {
            if (contact[0][contactlinks[j]] !== undefined) {
              // console.log(contactlinks[j], ":", contact[0][contactlinks[j]]);
              for (let i = 0; i < contact[0][contactlinks[j]].length; i++) {
                if (contact[0][contactlinks[j]][i].check) {
                  if (
                    contactlinks[j] === "numbers" ||
                    contactlinks[j] === "call"
                  ) {
                    vCardData.addPhoneNumber(
                      contact[0][contactlinks[j]][i].value,
                      "Work"
                    );
                  } else if (contactlinks[j] === "emails") {
                    vCardData.addEmail(
                      contact[0][contactlinks[j]][i].value,
                      "Email"
                    );
                  } else if (contactlinks[j] === "website") {
                    vCardData.addURL(contact[0][contactlinks[j]][i].value);
                  } else if (contactlinks[j] === "addresses") {
                    vCardData.addAddress(contact[0][contactlinks[j]][i].value);
                  } else if (contactlinks[j] === "file") {
                    vCardData.addURL(contact[0][contactlinks[j]][i].value);
                  } else if (contactlinks[j] === "applink") {
                    vCardData.addURL(contact[0][contactlinks[j]][i].value);
                  } else if (contactlinks[j] === "embedded") {
                    vCardData.addSocial(
                      "https://www.youtube.com/watch?v=" +
                        contact[0][contactlinks[j]][i].value,
                      "youtube",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].value !== undefined
                  ) {
                    if (
                      contact[0][contactlinks[j]][i].value.startsWith(
                        "https://"
                      ) ||
                      contact[0][contactlinks[j]][i].value.startsWith(
                        "http://"
                      ) ||
                      contact[0][contactlinks[j]][i].value.startsWith(
                        "Https://"
                      ) ||
                      contact[0][contactlinks[j]][i].value.startsWith("Http://")
                    ) {
                      const val =
                        contact[0][contactlinks[j]][i].value.split("/");
                      const value = val[val.length - 1];
                      var name = "";
                      if (
                        contact[0][contactlinks[j]][i].value.includes(
                          contactlinks[j]
                        )
                      ) {
                        name = contactlinks[j];
                        vCardData.addSocial(
                          contact[0][contactlinks[j]][i].value,
                          name,
                          value
                        );
                      } else if (contactlinks[j] === "customlink") {
                        vCardData.addSocial(
                          contact[0][contactlinks[j]][i].value,
                          "custom link",
                          value
                        );
                      }
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Twitch"
                    ) {
                      vCardData.addSocial(
                        "https://twitch.tv/" +
                          contact[0][contactlinks[j]][i].value,
                        "twitch",
                        contact[0][contactlinks[j]][i].value
                      );
                    } else if (contact[0][contactlinks[j]][i].name === "Cash") {
                      vCardData.addSocial(
                        "https://cash.app/$" +
                          contact[0][contactlinks[j]][i].value,
                        "cash",
                        contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Venmo"
                    ) {
                      vCardData.addSocial(
                        "https://venmo.com/" +
                          contact[0][contactlinks[j]][i].value,
                        "venmo",
                        contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "twitter" ||
                      contact[0][contactlinks[j]][i].name === "X" ||
                      contact[0][contactlinks[j]][i].name === "Twitter"
                    ) {
                      vCardData.addSocial(
                        "https://twitter.com/" +
                          contact[0][contactlinks[j]][i].value,
                        "twitter",
                        contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Clubhouse"
                    ) {
                      vCardData.addSocial(
                        "https://clubhouse.com/@" +
                          contact[0][contactlinks[j]][i].value,
                        "clubhouse",
                        contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Telegram"
                    ) {
                      vCardData.addSocial(
                        "https://t.me/" + contact[0][contactlinks[j]][i].value,
                        "telegram",
                        contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Pinterest"
                    ) {
                      vCardData.addSocial(
                        "https://pinterest.com/" +
                          contact[0][contactlinks[j]][i].value,
                        "pinterest",
                        contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Instagram"
                    ) {
                      vCardData.addSocial(
                        `https://www.instagram.com/${
                          contact[0][contactlinks[j]][i].value
                        }`,
                        "instagram",
                        contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Tiktok"
                    ) {
                      vCardData.addSocial(
                        "https://tiktok.com/@" +
                          contact[0][contactlinks[j]][i].value,
                        "tiktok",
                        contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Snapchat"
                    ) {
                      vCardData.addSocial(
                        "https://snapchat.com/add/" +
                          contact[0][contactlinks[j]][i].value,
                        "snapchat",
                        contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Whatsapp"
                    ) {
                      vCardData.addSocial(
                        "whatsapp://send?phone=" +
                          contact[0][contactlinks[j]][i].value,
                        "whatsapp"
                      );
                    }
                  } else {
                  }
                }
              }
            }
          }

          // Direct VCF DOwnloader Code
          const element = document.createElement("a");

          element.setAttribute(
            "href",
            "data:text/vcard;charset=utf-8," +
              encodeURIComponent(vCardData.toString())
          );
          if (contact[0].name) {
            element.setAttribute("download", `${contact[0].name}.vcf`);
          } else {
            element.setAttribute("download", "Contact.vcf");
          }
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);

          setLoading(false);
        });
    } else if (os === "Android") {
      setLoading(true);
      console.log("In android");
      var vCardData = new VCard();
      // firebase
      //   .database()
      //   .ref(`users/${routeParams.uid}`)
      //   .once("value")
      //   .then((snapshot) => {
      //     const emaildata = snapshot.val();
      //     console.log("emaildata", emaildata.email)

      //     vCardData.addEmail(
      //       emaildata.email || "Email does not exist",
      //       "Email"
      //     );
      //   });

      firebase
        .database()
        .ref(`users/${routeParams.uid}/cards`)
        .once("value")
        .then(async (snapshot) => {
          var splitcardIndex = [];
          const cards = snapshot.val();
          if (routeParams.index.includes("-")) {
            splitcardIndex = routeParams.index.split("-");
          }

          const contact = cards.filter((data) => {
            if (splitcardIndex.length > 0) {
              return data.index === splitcardIndex[0];
            } else {
              return data.index === routeParams.index;
            }
          });
          // console.log("qP:", routeParams.file);
          // console.log("ssss: ", contact[0].image);

          // console.log("ABCCC: ", contact[0].website);
          // const imageBase64 = contact[0]["image"].toString("base64");
          const res = await axios.get("https://backend.popitapp.nl/image", {
            params: {
              url: contact[0].image,
            },
          });
          const name1 = contact[0].name.includes(" ")
            ? contact[0].name.split(" ")
            : [contact[0].name]; 

          const first_name = name1[0] || ""; 
          const last_name = name1.length > 1 ? name1[name1.length - 1] : ""; 
          const middle_name = name1.length === 3 ? name1[1] : ""; 
          vCardData
            .addName(last_name, first_name, middle_name)
            .addCompany(contact[0].company || "No Company")
            .addJobtitle(contact[0].job || "No Job")
            .addRole(contact[0].job || "No Role")
            .addNote(contact[0].bio || "No Bio")
            .addPhoto(res.data.img, res.data.mime);

          for (let j = 0; j < contactlinks.length; j++) {
            if (contact[0][contactlinks[j]] !== undefined) {
              // console.log(contactlinks[j], ":", contact[0][contactlinks[j]]);
              for (let i = 0; i < contact[0][contactlinks[j]].length; i++) {
                if (contact[0][contactlinks[j]][i].check) {
                  if (
                    contactlinks[j] === "numbers" ||
                    contactlinks[j] === "whatsapp" ||
                    contactlinks[j] === "call"
                  ) {
                    vCardData.addPhoneNumber(
                      contact[0][contactlinks[j]][i].value,
                      "Work"
                    );
                  } else if (contactlinks[j] === "emails") {
                    vCardData.addEmail(
                      contact[0][contactlinks[j]][i].value,
                      "Email"
                    );
                  } else if (contactlinks[j] === "file") {
                    vCardData.addURL(contact[0][contactlinks[j]][i].value);
                  } else if (contactlinks[j] === "applink") {
                    vCardData.addURL(contact[0][contactlinks[j]][i].value);
                  } else if (contactlinks[j] === "website") {
                    vCardData.addURL(contact[0][contactlinks[j]][i].value);
                  } else if (contactlinks[j] === "addresses") {
                    vCardData.addAddress(contact[0][contactlinks[j]][i].value);
                  } else if (
                    contact[0][contactlinks[j]][i].value !== undefined
                  ) {
                    if (
                      contact[0][contactlinks[j]][i].value.startsWith(
                        "https://"
                      ) ||
                      contact[0][contactlinks[j]][i].value.startsWith(
                        "http://"
                      ) ||
                      contact[0][contactlinks[j]][i].value.startsWith(
                        "Https://"
                      ) ||
                      contact[0][contactlinks[j]][i].value.startsWith("Http://")
                    ) {
                      
                      vCardData.addURL(contact[0][contactlinks[j]][i].value);
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Twitch"
                    ) {
                      vCardData.addURL(
                        "https://twitch.tv/" +
                          contact[0][contactlinks[j]][i].value
                      );
                    } else if (contact[0][contactlinks[j]][i].name === "Cash") {
                      vCardData.addURL(
                        "https://cash.app/$" +
                          contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Venmo"
                    ) {
                      vCardData.addURL(
                        "https://venmo.com/" +
                          contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "twitter" ||
                      contact[0][contactlinks[j]][i].name === "X" ||
                      contact[0][contactlinks[j]][i].name === "Twitter"
                    ) {
                      vCardData.addURL(
                        "https://twitter.com/" +
                          contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Clubhouse"
                    ) {
                      vCardData.addURL(
                        "https://clubhouse.com/@" +
                          contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Telegram"
                    ) {
                      vCardData.addURL(
                        "https://t.me/" + contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Pinterest"
                    ) {
                      vCardData.addURL(
                        "https://pinterest.com/" +
                          contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Instagram"
                    ) {
                      vCardData.addURL(
                        `https://www.instagram.com/${
                          contact[0][contactlinks[j]][i].value
                        }`
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Tiktok"
                    ) {
                      vCardData.addURL(
                        "https://tiktok.com/@" +
                          contact[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0][contactlinks[j]][i].name === "Snapchat"
                    ) {
                      vCardData.addURL(
                        "https://snapchat.com/add/" +
                          contact[0][contactlinks[j]][i].value
                      );
                    }
                  } else {
                  }
                }
              }
            }
          }

          // Direct VCF DOwnloader Code
          const element = document.createElement("a");

          element.setAttribute(
            "href",
            "data:text/vcard;charset=utf-8," +
              encodeURIComponent(vCardData.toString())
          );
          if (contact[0].name) {
            element.setAttribute("download", `${contact[0].name}.vcf`);
          } else {
            element.setAttribute("download", `Contact.vcf`);
          }
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          setLoading(false);
        });
    }
  };

  const contact_card = () => {
    var middle_name= '';
    const os = detectOS();

    if (os === "iOS") {
      var vCardData = new VCard();
      firebase
        .database()
        .ref(`users/${routeParams.uid}/cards`)
        .once("value")
        .then(async (snapshot) => {
          const cards = snapshot.val();

          const contact = cards.filter((data) => {
            return data.index === routeParams.index;
          });

          const res = await axios.get("https://backend.popitapp.nl/image", {
            params: {
              url: contact[0].image,
            },
          });
          const name1 = contact[0].name.includes(" ")
            ? contact[0].name.split(" ")
            : [contact[0].name];

          const first_name = name1[0] || "";
          const last_name = name1.length > 1 ? name1[name1.length - 1] : "";
          const middle_name = name1.length === 3 ? name1[1] : ""; 

          vCardData
            .addName(last_name, first_name, middle_name)
            .addCompany(contact[0].company || "No Company")
            .addJobtitle(contact[0].job || "No Job")
            .addRole(contact[0].job || "No Role")
            .addNote(contact[0].bio || "No Bio")
            .addPhoneNumber(contact[0].number, "PREF;WORK")
            .addPhoto(res.data.img, res.data.mime);

          for (let j = 0; j < contactlinks.length; j++) {
            if (contact[0].ContactCard[0][contactlinks[j]] !== undefined) {
              for (
                let i = 0;
                i < contact[0].ContactCard[0][contactlinks[j]].length;
                i++
              ) {
                if (
                  contact[0].ContactCard[0][contactlinks[j]][i].check === true
                ) {
                  
                  if (
                    contactlinks[j] === "numbers" ||
                    contactlinks[j] === "whatsapp" ||
                    contactlinks[j] === "call"
                  ) {
                    vCardData.addPhoneNumber(
                      contact[0].ContactCard[0][contactlinks[j]][i].value,
                      "Work"
                    );
                  } else if (contactlinks[j] === "emails") {
                    vCardData.addEmail(
                      contact[0].ContactCard[0][contactlinks[j]][i].value,
                      "Email"
                    );
                  } else if (contactlinks[j] === "website") {
                    vCardData.addURL(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (contactlinks[j] === "addresses") {
                    vCardData.addAddress(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0].ContactCard[0][contactlinks[j]][i].name
                      .charAt(0)
                      .toUpperCase() +
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .slice(1)
                        .toLowerCase() ===
                    "File"
                  ) {
                    // console.log("In fffff")
                    vCardData.addURL(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (contactlinks[j] === "embedded") {
                    vCardData.addSocial(
                      "https://www.youtube.com/watch?v=" +
                        contact[0].ContactCard[0][contactlinks[j]][i].value,
                      "youtube",
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0].ContactCard[0][contactlinks[j]][i].value !==
                    undefined
                  ) {
                    if (
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("https://") ||
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("http://") ||
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("Https://") ||
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("Http://")
                    ) {
                      // console.log("IN http");
                      const val =
                        contact[0].ContactCard[0][contactlinks[j]][
                          i
                        ].value.split("/");
                      const values = val[val.length - 1];
                      var name = "";

                      if (
                        contact[0].ContactCard[0][contactlinks[j]][
                          i
                        ].value.includes(contactlinks[j])
                      ) {
                        name = contactlinks[j];
                        // console.log("name", name, "and", values);
                        vCardData.addSocial(
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                          name,
                          values
                        );
                      }
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Applink"
                    ) {
                      vCardData.addURL(
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Twitch"
                    ) {
                      vCardData.addSocial(
                        "https://twitch.tv/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "twitch",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Cash"
                    ) {
                      vCardData.addSocial(
                        "https://cash.app/$" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "cash",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Venmo"
                    ) {
                      vCardData.addSocial(
                        "https://venmo.com/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "venmo",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                        "twitter" ||
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                        "X" ||
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                        "Twitter"
                    ) {
                      vCardData.addSocial(
                        "https://twitter.com/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "twitter",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Clubhouse"
                    ) {
                      vCardData.addSocial(
                        "https://clubhouse.com/@" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "clubhouse",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Telegram"
                    ) {
                      vCardData.addSocial(
                        "https://t.me/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "telegram",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Pinterest"
                    ) {
                      vCardData.addSocial(
                        "https://pinterest.com/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "pinterest",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Instagram"
                    ) {
                      // console.log("IN instagram!!!");
                      vCardData.addSocial(
                        `https://www.instagram.com/${
                          contact[0].ContactCard[0][contactlinks[j]][i].value
                        }`,
                        "Instagram",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Tiktok"
                    ) {
                      vCardData.addSocial(
                        "https://tiktok.com/@" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "tiktok",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Snapchat"
                    ) {
                      vCardData.addSocial(
                        "https://snapchat.com/add/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "snapchat",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    }
                  }
                }
              }
            }
          }

          // Direct VCF DOwnloader Code
          const element = document.createElement("a");

          element.setAttribute(
            "href",
            "data:text/vcard;charset=utf-8," +
              encodeURIComponent(vCardData.toString())
          );
          if (contact[0].name) {
            element.setAttribute("download", `${contact[0].name}.vcf`);
          } else {
            element.setAttribute("download", "Contact.vcf");
          }
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        });
    } else if (os === "Android") {
      var vCardData = new VCard();
      firebase
        .database()
        .ref(`users/${routeParams.uid}/cards`)
        .once("value")
        .then(async (snapshot) => {
          const cards = snapshot.val();
          // console.log("card: ", cards)

          const contact = cards.filter((data) => {
            return data.index === routeParams.index;
          });

          const res = await axios.get("https://backend.popitapp.nl/image", {
            params: {
              url: contact[0].image,
            },
          });
          // console.log("card:",cards)
          const name1 = contact[0].name.includes(" ") 
            ? contact[0].name.split(" ")
            : [contact[0].name];

          const first_name = name1[0] || "";
          const last_name = name1.length > 1 ? name1[name1.length - 1] : "";
          const middle_name = name1.length === 3 ? name1[1] : ""; 
          vCardData
            .addName(last_name, first_name, middle_name)
            .addCompany(contact[0].company || "No Company")
            .addJobtitle(contact[0].job || "No Job")
            .addRole(contact[0].job || "No Role")
            .addNote(contact[0].bio || "No Bio")
            .addPhoto(res.data.img, res.data.mime);

          for (let j = 0; j < contactlinks.length; j++) {
            if (contact[0].ContactCard[0][contactlinks[j]] !== undefined) {
              for (
                let i = 0;
                i < contact[0].ContactCard[0][contactlinks[j]].length;
                i++
              ) {
                if (
                  contact[0].ContactCard[0][contactlinks[j]][i].check === true
                ) {
                  if (
                    contactlinks[j] === "numbers" ||
                    contactlinks[j] === "call"
                  ) {
                    vCardData.addPhoneNumber(
                      contact[0].ContactCard[0][contactlinks[j]][i].value,
                      "Work"
                    );
                  } else if (contactlinks[j] === "emails") {
                    vCardData.addEmail(
                      contact[0].ContactCard[0][contactlinks[j]][i].value,
                      "Email"
                    );
                  } else if (
                    contact[0].ContactCard[0][contactlinks[j]][i].name
                      .charAt(0)
                      .toUpperCase() +
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .slice(1)
                        .toLowerCase() ===
                    "File"
                  ) {
                    vCardData.addURL(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0].ContactCard[0][contactlinks[j]][i].name
                      .charAt(0)
                      .toUpperCase() +
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .slice(1)
                        .toLowerCase() ===
                    "Applink"
                  ) {
                    vCardData.addURL(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (contactlinks[j] === "website") {
                    vCardData.addURL(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (contactlinks[j] === "addresses") {
                    vCardData.addAddress(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (contactlinks[j] === "embedded") {
                    vCardData.addURL(
                      "https://www.youtube.com/watch?v=" +
                        contact[0].ContactCard[0][contactlinks[j]][i].value,
                      "youtube",
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0].ContactCard[0][contactlinks[j]][i].value !==
                    undefined
                  ) {
                    if (
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("https://") ||
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("http://") ||
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("Https://") ||
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("Http://")
                    ) {
                      const val =
                        contact[0].ContactCard[0][contactlinks[j]][
                          i
                        ].value.split("/");
                      const values = val[val.length - 1];
                      var name = "";
                      if (
                        contact[0].ContactCard[0][contactlinks[j]][
                          i
                        ].value.includes(contactlinks[j])
                      ) {
                        name = contactlinks[j];
                        vCardData.addURL(
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                          name,
                          values
                        );
                      }
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Twitch"
                    ) {
                      vCardData.addURL(
                        "https://twitch.tv/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "twitch",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Cash"
                    ) {
                      vCardData.addURL(
                        "https://cash.app/$" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "cash",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Venmo"
                    ) {
                      vCardData.addURL(
                        "https://venmo.com/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "venmo",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                        "twitter" ||
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                        "X" ||
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                        "Twitter"
                    ) {
                      vCardData.addURL(
                        "https://twitter.com/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "twitter",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Clubhouse"
                    ) {
                      vCardData.addURL(
                        "https://clubhouse.com/@" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "clubhouse",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Telegram"
                    ) {
                      vCardData.addURL(
                        "https://t.me/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "telegram",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Pinterest"
                    ) {
                      vCardData.addURL(
                        "https://pinterest.com/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "pinterest",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Instagram"
                    ) {
                      vCardData.addURL(
                        `https://www.instagram.com/${
                          contact[0].ContactCard[0][contactlinks[j]][i].value
                        }`,
                        "Instagram",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Tiktok"
                    ) {
                      vCardData.addURL(
                        "https://tiktok.com/@" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "tiktok",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Snapchat"
                    ) {
                      vCardData.addURL(
                        "https://snapchat.com/add/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "snapchat",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Whatsapp"
                    ) {
                      vCardData.addSocial(
                        "whatsapp//send?phone=" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "whatsapp"
                      );
                    }
                  }
                }
              }
            }
          }

          // Direct VCF DOwnloader Code
          const element = document.createElement("a");

          element.setAttribute(
            "href",
            "data:text/vcard;charset=utf-8," +
              encodeURIComponent(vCardData.toString())
          );
          if (contact[0].name) {
            element.setAttribute("download", `${contact[0].name}.vcf`);
          } else {
            element.setAttribute("download", "Contact.vcf");
          }
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        });
    }
  };

  function detectOS() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      return "iOS";
    } else if (/android/.test(userAgent)) {
      return "Android";
    } else {
      return "Unknown";
    }
  }
  

  useEffect(() => {
    

    const noSelectElements = document.querySelectorAll(".no-select");
    noSelectElements.forEach((element) => {
      element.style.webkitTouchCallout = "none";
    });
  }, []);

  React.useEffect(() => {
    userData(); 
    pop(); 
  }, []);


  const closeModal = () => {
    setLeadCapture(false); 
  };

  const handleOverlayClick = (e) => {
    // Close modal if the clicked element is the overlay
    if (e.target.classList.contains("modal-overlay")) {
      closeModal();
    }
  };

  
  

  return (
    <div>
      {dataloading ? (
        <div className="video-container">
          <video autoPlay loop muted className="loading-video">
            <source src={loadingVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : (
        <div className="mainDiv">
          <div
            className="childDiv"
            onCopy={(e) => e.preventDefault()}
            onCopyCapture={(e) => e.preventDefault()}
            style={
              alignment === "portrait"
                ? {
                    // height: "100vh",
                    // background: color
                    //   ? `linear-gradient(to Bottom, ${color}, ${color}, white)`
                    //   : "white",
                    ...(color === "#eb5757"
                      ? { background: "rgba(235, 87, 87, 0.26)" }
                      : color === "#f2994a"
                      ? { background: "rgba(242, 153, 74, 0.26)" }
                      : color === "#f2c94c"
                      ? { background: "rgba(242, 201, 76, 0.26)" }
                      : color === "#219653"
                      ? { background: "rgba(33, 150, 83, 0.26)" }
                      : color === "#2f80ed"
                      ? { background: "rgba(47, 128, 237, 0.26)" }
                      : color === "#9b51e0"
                      ? { background: "rgba(155, 81, 224, 0.26)" }
                      : {
                          background: `rgba(${parseInt(
                            color.slice(1, 3),
                            16
                          )}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
                            color.slice(5, 7),
                            16
                          )}, ${0.26})`,
                        }),
                  }
                : {
                    // height: "100vh",

                    // background: color
                    //   ? `linear-gradient(to Bottom, ${color}, white, white)`
                    //   : "white",
                    ...(color === "#eb5757"
                      ? { background: "rgba(235, 87, 87, 0.26)" }
                      : color === "#f2994a"
                      ? { background: "rgba(242, 153, 74, 0.26)" }
                      : color === "#f2c94c"
                      ? { background: "rgba(242, 201, 76, 0.26)" }
                      : color === "#219653"
                      ? { background: "rgba(33, 150, 83, 0.26)" }
                      : color === "#2f80ed"
                      ? { background: "rgba(47, 128, 237, 0.26)" }
                      : color === "#9b51e0"
                      ? { background: "rgba(155, 81, 224, 0.26)" }
                      : {
                          background: `rgba(${parseInt(
                            color.slice(1, 3),
                            16
                          )}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
                            color.slice(5, 7),
                            16
                          )}, ${0.26})`,
                        }),
                  }
            }
          >
            {leadCapture && (
              <div className="modal-overlay" onClick={handleOverlayClick}>
                <div className="modal-content">
                  {/* Image at the top center */}
                  <div className="image-wrapper">
                    {cropped && (
                      <img
                        src={cropped}
                        alt="Profile Image"
                        className="modal-image"
                      />
                    )}
                    {!cropped && (
                      <div
                        style={{
                          display: "flex",
                          width: "80px",
                          height: "80px",
                          borderRadius: "75px",
                          borderWidth: "4px",
                          borderColor: "white",
                          backgroundColor: "#F2F3F5",
                          alignItems: "center",
                          position: "relative",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={User}
                          alt="User"
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                    )}
                  </div>
                  <h2>{lead.heading ? lead.heading : "Share your info"}</h2>
                  <div className="modal-content-inner">
                    <form onSubmit={handleSubmitLead}>
                      {/* Input Fields */}
                      {lead.inputFields?.map((field, index) => (
                        <div className="input-container" key={index}>
                          <input
                            type={field.name === "email" ? "email" : "text"}
                            name={field.name}
                            placeholder={field.value}
                            required={field.required}
                            onChange={(e) =>
                              handleInputChange(
                                "inputFields",
                                index,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ))}

                      {/* Extra Fields */}
                      {lead.extraFields?.map((field, index) =>
                        field.type === "checkbox" ? (
                          <div className="input-container-lead" key={index}>
                            <input
                              type={field.type}
                              name={field.name}
                              placeholder={field.value}
                              required={field.required}
                              onChange={(e) =>
                                handleInputChange(
                                  "extraFields",
                                  index,
                                  e.target.value
                                )
                              }
                            />
                            {field.type === "checkbox" && <p>{field.value}</p>}
                          </div>
                        ) : (
                          <div className="input-container" key={index}>
                            <input
                              type={field.type}
                              name={field.name}
                              placeholder={field.value}
                              required={field.required}
                              onChange={(e) =>
                                handleInputChange(
                                  "extraFields",
                                  index,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        )
                      )}

                      <button
                        type="submit"
                        style={{
                          backgroundColor: linkColor ? linkColor : color,
                          color: "white",
                          padding: 10,
                          border: "none",
                          borderRadius: 25,
                          cursor: "pointer",
                          fontSize: 16,
                          width: "70%",
                          alignSelf: "center",
                        }}
                      >
                        {lead.connect ? lead.connect : "Connect"}
                      </button>
                      <p className="privacy-text">{lead.disclaimer}</p>
                    </form>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                position: "relative",
                background: "white",
              }}
            >
              <img
                onContextMenu={(e) => e.preventDefault()}
                src={
                  alignment === "portrait" ? (cropped ? cropped : cover) : cover
                }
                alt="Banner"
                style={{
                  width: "100%",
                  height: alignment === "portrait" ? "50vh" : 180,
                  objectFit: "cover",
                  maxWidth: "100%",
                  display: "flex",
                }}
              />

              {alignment === "portrait" && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 250, // Adjust the height of the gradient overlay

                    // background: `linear-gradient(to top, ${color} 0%, rgba(0, 18, 18, 0) 100%, rgba(128, 128, 128, 0) 100%)`,
                    background:
                      color === "#eb5757"
                        ? "linear-gradient(to top, rgba(235, 87, 87, 0.26), rgba(235, 87, 87, 0.01))"
                        : color === "#f2994a"
                        ? "linear-gradient(to top, rgba(242, 153, 74, 0.26),rgba(242, 153, 74, 0.01))"
                        : color === "#f2c94c"
                        ? "linear-gradient(to top,  rgba(242, 201, 76, 0.26), rgba(242, 201, 76, 0.01))"
                        : color === "#219653"
                        ? "linear-gradient(to top,  rgba(33, 150, 83, 0.26), rgba(33, 150, 83, 0.01))"
                        : color === "#2f80ed"
                        ? "linear-gradient(to top, rgba(47, 128, 237, 0.26),rgba(47, 128, 237, 0.01))"
                        : color === "#9b51e0"
                        ? "linear-gradient(to top,  rgba(155, 81, 224, 0.26), rgba(155, 81, 224, 0.01))"
                        : `linear-gradient(to top, rgba(${parseInt(
                            color.slice(1, 3),
                            16
                          )}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
                            color.slice(5, 7),
                            16
                          )}, 0.26), rgba(${parseInt(
                            color.slice(1, 3),
                            16
                          )}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
                            color.slice(5, 7),
                            16
                          )}, 0.01))`,
                  }}
                />
              )}

              <div
                className="imagedivstyle"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {cropped && alignment !== "portrait" && (
                  <div
                    style={
                      alignment === "center"
                        ? {
                            position: "absolute",
                            // top: "50%",
                            left: "50%",
                            transform: "translate(-50%, 0%)",
                          }
                        : alignment === "start"
                        ? {
                            position: "absolute",
                            right: 20,
                          }
                        : {}
                    }
                  >
                    <Picture
                      source={cropped}
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "100px",
                        borderWidth: "4px",
                        borderColor: "white",
                        backgroundColor: "#F2F3F5",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        borderStyle: "solid",
                      }}
                    />
                  </div>
                )}

                {!cropped && alignment !== "portrait" && (
                  <div
                    style={
                      alignment === "center"
                        ? {
                            position: "absolute",
                            // top: "50%",
                            left: "50%",
                            transform: "translate(-50%, 0%)",
                          }
                        : alignment === "start"
                        ? {
                            position: "absolute",
                            right: 20,
                          }
                        : {}
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "150px",
                        height: "150px",
                        borderRadius: "75px",
                        borderWidth: "4px",
                        borderColor: "white",
                        backgroundColor: "#F2F3F5",
                        alignItems: "center",
                        position: "relative",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        onContextMenu={(e) => e.preventDefault()}
                        src={User}
                        alt="User"
                        style={{ width: "70px", height: "70px" }}
                      />
                    </div>
                  </div>
                )}

                {logo && alignment !== "portrait" ? (
                  <div
                    style={
                      alignment === "center"
                        ? {
                            width: 50,
                            height: 50,
                            borderRadius: 75,
                            borderWidth: 4,
                            elevation: 4,
                            backgroundColor: "white",
                            overflow: "hidden",
                            borderStyle: "solid",
                            borderColor: logo ? "white" : "black",
                            position: "absolute",
                            // top: "50%",
                            right: "25%",
                            transform: "translate(162%, 153%)",
                            zIndex: 1,
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                          }
                        : alignment === "start"
                        ? {
                            width: 50,
                            height: 50,
                            borderRadius: 75,
                            borderWidth: 4,
                            elevation: 4,
                            backgroundColor: "white",
                            overflow: "hidden",
                            borderStyle: "solid",
                            borderColor: logo ? "white" : "black",
                            position: "absolute",
                            // top: "50%",
                            right: 90,
                            transform: "translate(162%, 153%)",
                            zIndex: 1,
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                          }
                        : {}
                    }
                  >
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={
                        membership === "normal"
                          ? require("../images/pop_it.png")
                          : logo
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ) : alignment !== "portrait" ? (
                  <div
                    style={
                      alignment === "center"
                        ? {
                            width: 50,
                            height: 50,
                            borderRadius: 75,
                            borderWidth: 4,
                            elevation: 4,
                            backgroundColor: "white",
                            overflow: "hidden",
                            borderStyle: "solid",
                            borderColor: logo ? "white" : "black",
                            position: "absolute",
                            // top: "50%",
                            right: "25%",
                            transform: "translate(162%, 153%)",
                            zIndex: 1,
                          }
                        : alignment === "start"
                        ? {
                            width: 50,
                            height: 50,
                            borderRadius: 75,
                            borderWidth: 4,
                            elevation: 4,
                            backgroundColor: "white",
                            overflow: "hidden",
                            borderStyle: "solid",
                            borderColor: logo ? "white" : "black",
                            position: "absolute",
                            // top: "50%",
                            right: 90,
                            transform: "translate(162%, 153%)",
                            zIndex: 1,
                          }
                        : {}
                    }
                  >
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={require("../images/pop_it.png")}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            {/* Job and COmpany below */}
            {alignment === "portrait" && (
              <div
                style={
                  {
                    // background: color
                    //   ? `linear-gradient(to bottom, ${color}, white)`
                    //   : "white",
                  }
                }
              >
                {job && company ? (
                  <div
                    style={
                      alignment === "center"
                        ? {
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: "6%",
                          }
                        : alignment === "start"
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: "6%",
                          }
                        : {
                            display: "flex",
                            flexDirection: "row",
                          }
                    }
                  >
                    <div className="portraitDiv1">
                      <div
                        style={
                          alignment === "center"
                            ? {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "15%",
                                paddingLeft: membership !== "normal" ? "6%" : 0,
                                width: "85%",
                              }
                            : alignment === "start"
                            ? {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "15%",
                                paddingLeft: "12%",
                                width: "85%",
                              }
                            : {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "5%",
                                paddingLeft: "12%",
                                width: "85%",
                              }
                        }
                      >
                        <p
                          style={{
                            fontFamily: userfont
                              ? userfont
                              : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                            fontWeight: 600,
                            fontSize: 24,
                            margin: 0,
                            // whiteSpace: "nowrap", // Prevents text from wrapping
                            // overflow: "hidden", // Hides any overflowing text
                            // textOverflow: "clip", // Adds ellipsis to indicate overflow
                            textAlign: "center",
                            wordBreak: "break-word",
                          }}
                        >
                          {name}
                        </p>
                        {membership !== "normal" ? (
                          <img
                            onContextMenu={(e) => e.preventDefault()}
                            src={verify}
                            alt="Verify"
                            style={{
                              width: 20,
                              height: 20,
                              paddingTop: "0.5%",
                              marginLeft: "2%",
                            }}
                          />
                        ) : null}
                      </div>

                      <p
                        style={
                          alignment === "center"
                            ? {
                                textAlign: "center",
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 600,
                                // fontStyle: "normal",
                                marginTop: 1,
                                marginBottom: 0,
                                marginLeft: 5,
                                marginRight: 5,
                                fontSize: 17,
                                wordBreak: "break-word",
                                // lineHeight: 1.43
                              }
                            : alignment === "start"
                            ? {
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 600,
                                // fontStyle: "normal",
                                marginTop: 1,
                                marginBottom: 0,
                                marginRight: 5,
                                fontSize: 17,
                                paddingLeft: "12%",
                                wordBreak: "break-word",
                                // lineHeight: 1.43
                              }
                            : {
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 600,
                                // fontStyle: "normal",
                                marginTop: 1,
                                marginBottom: 0,
                                marginRight: 5,
                                fontSize: 17,
                                paddingLeft: "12%",
                                wordBreak: "break-word",
                                // lineHeight: 1.43
                              }
                        }
                      >
                        {job} at {company}
                      </p>
                      <p
                        style={
                          alignment === "center"
                            ? {
                                textAlign: "center",
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                fontStyle: "normal",
                                marginTop: 4,
                                marginBottom: 20,
                                fontSize: 16,
                                wordBreak: "break-word",
                              }
                            : alignment === "start"
                            ? {
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                fontStyle: "normal",
                                marginTop: 4,
                                marginBottom: 20,
                                fontSize: 16,
                                paddingLeft: "12%",
                                wordBreak: "break-word",
                              }
                            : {
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                fontStyle: "normal",
                                marginTop: 4,
                                marginBottom: 20,
                                fontSize: 16,
                                paddingLeft: "12%",
                                wordBreak: "break-word",
                              }
                        }
                      >
                        {add}
                      </p>
                      <p
                        style={
                          alignment === "center"
                            ? {
                                textAlign: "center",
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                fontStyle: "normal",
                                marginTop: 1,
                                marginBottom: 20,
                                fontSize: 16,
                                marginLeft: 10,
                                marginRight: 10,
                                wordBreak: "break-word",
                              }
                            : alignment === "start"
                            ? {
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                fontStyle: "normal",
                                marginTop: 1,
                                marginBottom: 20,
                                fontSize: 16,
                                paddingLeft: "12%",
                                wordBreak: "break-word",
                              }
                            : {
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                fontStyle: "normal",
                                marginTop: 1,
                                marginBottom: 20,
                                fontSize: 16,
                                paddingLeft: "12%",
                                wordBreak: "break-word",
                              }
                        }
                      >
                        {bio}
                      </p>
                    </div>
                    <div className="portraitDiv2">
                      <div
                        style={{
                          width: 80,
                          height: 80,
                          borderRadius: 75,
                          borderWidth: 4,
                          elevation: 4,
                          backgroundColor: "white",
                          overflow: "hidden",
                          borderStyle: "solid",
                          borderColor: logo ? "white" : "black",
                          // position: "absolute",
                          // top: "50%",
                          // right: "25%",
                          // transform: "translate(162%, 153%)",
                          zIndex: 1,
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <img
                          onContextMenu={(e) => e.preventDefault()}
                          src={logo ? logo : require("../images/pop_it.png")}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={
                      alignment === "center"
                        ? {
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }
                        : alignment === "start"
                        ? {
                            display: "flex",
                            flexDirection: "column",
                          }
                        : {
                            display: "flex",
                            flexDirection: "row",
                          }
                    }
                  >
                    <div className="portraitDiv1">
                      <div
                        style={
                          alignment === "center"
                            ? {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "22%",
                                paddingLeft: membership !== "normal" ? "6%" : 0,
                                width: "90%",
                              }
                            : alignment === "start"
                            ? {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "22%",
                                paddingLeft:
                                  membership !== "normal" ? "12%" : 0,
                                width: "90%",
                              }
                            : {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "5%",
                                paddingLeft:
                                  membership !== "normal" ? "12%" : 0,
                                width: "90%",
                              }
                        }
                      >
                        <p
                          style={{
                            fontFamily: userfont
                              ? userfont
                              : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                            // fontStyle: "normal",

                            fontWeight: 600,
                            fontSize: 24,
                            margin: 0,
                            wordBreak: "break-word",
                          }}
                        >
                          {name}
                        </p>
                        {membership !== "normal" ? (
                          <img
                            onContextMenu={(e) => e.preventDefault()}
                            src={verify}
                            alt="Verify"
                            style={{
                              width: 20,
                              height: 20,
                              paddingTop: "0.5%",
                              marginLeft: "2%",
                            }}
                          />
                        ) : null}
                      </div>
                      <p
                        style={
                          alignment === "center"
                            ? {
                                textAlign: "center",
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 600,
                                // fontStyle: "normal",
                                marginTop: 1,
                                marginBottom: 0,
                                fontSize: 17,
                                wordBreak: "break-word",
                                // lineHeight: 1.43
                              }
                            : alignment === "start"
                            ? {
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 600,
                                // fontStyle: "normal",
                                marginTop: 1,
                                marginBottom: 0,
                                fontSize: 17,
                                paddingLeft:
                                  membership !== "normal" ? "12%" : 0,
                                wordBreak: "break-word",
                                // lineHeight: 1.43
                              }
                            : {
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 600,
                                // fontStyle: "normal",
                                marginTop: 1,
                                marginBottom: 0,
                                fontSize: 17,
                                paddingLeft:
                                  membership !== "normal" ? "12%" : 0,
                                wordBreak: "break-word",
                              }
                        }
                      >
                        {job ? job : null} {job && company ? "at" : null}{" "}
                        {company ? company : null}
                      </p>
                      <p
                        style={
                          alignment === "center"
                            ? {
                                textAlign: "center",
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                // fontStyle: "normal",
                                marginTop: 4,
                                fontSize: 16,
                                wordBreak: "break-word",
                              }
                            : alignment === "start"
                            ? {
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                // fontStyle: "normal",
                                marginTop: 4,
                                fontSize: 16,
                                wordBreak: "break-word",
                                paddingLeft:
                                  membership !== "normal" ? "12%" : 0,
                              }
                            : {
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                // fontStyle: "normal",
                                marginTop: 4,
                                fontSize: 16,
                                wordBreak: "break-word",
                                paddingLeft:
                                  membership !== "normal" ? "12%" : 0,
                              }
                        }
                      >
                        {add}
                      </p>
                      <p
                        style={
                          alignment === "center"
                            ? {
                                textAlign: "center",
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                fontStyle: "normal",
                                marginTop: 1,
                                fontSize: 16,
                                marginLeft: 10,
                                marginRight: 10,
                                marginBottom: 20,
                                wordBreak: "break-word",
                              }
                            : alignment === "start"
                            ? {
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                fontStyle: "normal",
                                marginTop: 1,
                                fontSize: 16,
                                marginRight: 50,
                                wordBreak: "break-word",
                                marginBottom: 20,
                                paddingLeft:
                                  membership !== "normal" ? "12%" : 0,
                              }
                            : {
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                fontStyle: "normal",
                                marginTop: 1,
                                fontSize: 16,
                                marginBottom: 20,
                                wordBreak: "break-word",
                                paddingLeft:
                                  membership !== "normal" ? "12%" : 0,
                              }
                        }
                      >
                        {bio}
                      </p>
                    </div>
                    <div className="portraitDiv2">
                      {logo && (
                        <div
                          style={{
                            width: 80,
                            height: 80,
                            borderRadius: 75,
                            borderWidth: 4,
                            elevation: 4,
                            backgroundColor: "white",
                            overflow: "hidden",
                            borderStyle: "solid",
                            borderColor: logo ? "white" : "black",
                            // position: "absolute",
                            // top: "50%",
                            // right: "25%",
                            // transform: "translate(162%, 153%)",
                            zIndex: 1,
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <img
                            onContextMenu={(e) => e.preventDefault()}
                            src={
                              membership === "normal"
                                ? require("../images/pop_it.png")
                                : logo
                            }
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {alignment !== "portrait" && (
              <div
                style={
                  {
                    // background: color
                    //   ? `linear-gradient(to bottom, ${color}, white)`
                    //   : "white",
                  }
                }
              >
                {job && company ? (
                  <div
                    style={
                      alignment === "center"
                        ? {
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: "6%",
                            paddingRight: "10%",
                            paddingLeft: "10%",
                          }
                        : alignment === "start"
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: "6%",
                            paddingRight: "10%",
                          }
                        : {}
                    }
                  >
                    <div
                      style={
                        alignment === "center"
                          ? {
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "15%",
                              paddingLeft: membership !== "normal" ? "6%" : 0,
                              width: "85%",
                            }
                          : alignment === "start"
                          ? {
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: "15%",
                              paddingLeft: "12%",
                              width: "85%",
                            }
                          : {}
                      }
                    >
                      <p
                        style={{
                          fontFamily: userfont
                            ? userfont
                            : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                          // fontStyle: "normal",

                          fontWeight: 600,
                          fontSize: 24,
                          margin: 0,
                          // whiteSpace: "nowrap", // Prevents text from wrapping
                          // overflow: "hidden", // Hides any overflowing text
                          // textOverflow: "clip", // Adds ellipsis to indicate overflow
                          textAlign: "center",
                          wordBreak: "break-word",
                        }}
                      >
                        {name}
                      </p>
                      {membership !== "normal" ? (
                        <img
                          onContextMenu={(e) => e.preventDefault()}
                          src={verify}
                          alt="Verify"
                          style={{
                            width: 20,
                            height: 20,
                            paddingTop: "0.5%",
                            marginLeft: "2%",
                            wordBreak: "break-word",
                          }}
                        />
                      ) : null}
                    </div>

                    <p
                      style={
                        alignment === "center"
                          ? {
                              textAlign: "center",
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 600,
                              // fontStyle: "normal",
                              marginTop: 1,
                              marginBottom: 0,
                              marginLeft: 5,
                              marginRight: 5,
                              fontSize: 17,
                              // lineHeight: 1.43
                              wordBreak: "break-word",
                            }
                          : alignment === "start"
                          ? {
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 600,
                              // fontStyle: "normal",
                              marginTop: 1,
                              marginBottom: 0,
                              marginRight: 5,
                              fontSize: 17,
                              paddingLeft: "12%",
                              // lineHeight: 1.43
                              wordBreak: "break-word",
                            }
                          : {}
                      }
                    >
                      {job} at {company}
                    </p>
                    <p
                      style={
                        alignment === "center"
                          ? {
                              textAlign: "center",
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 400,
                              fontStyle: "normal",
                              marginTop: 4,
                              marginBottom: 20,
                              fontSize: 16,
                              wordBreak: "break-word",
                            }
                          : alignment === "start"
                          ? {
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 400,
                              fontStyle: "normal",
                              marginTop: 4,
                              marginBottom: 20,
                              fontSize: 16,
                              paddingLeft: "12%",
                              wordBreak: "break-word",
                            }
                          : {}
                      }
                    >
                      {add}
                    </p>
                    <p
                      style={
                        alignment === "center"
                          ? {
                              textAlign: "center",
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 400,
                              fontStyle: "normal",
                              marginTop: 1,
                              marginBottom: 20,
                              fontSize: 16,
                              marginLeft: 10,
                              marginRight: 10,
                              wordBreak: "break-word",
                            }
                          : alignment === "start"
                          ? {
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 400,
                              fontStyle: "normal",
                              marginTop: 1,
                              marginBottom: 20,
                              fontSize: 16,
                              paddingLeft: "12%",
                              wordBreak: "break-word",
                            }
                          : {}
                      }
                    >
                      {bio}
                    </p>
                  </div>
                ) : (
                  <div
                    style={
                      alignment === "center"
                        ? {
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            paddingRight: "10%",
                            paddingLeft: "10%",
                          }
                        : alignment === "start"
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            paddingRight: "10%",
                          }
                        : {}
                    }
                  >
                    <div
                      style={
                        alignment === "center"
                          ? {
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "22%",
                              paddingLeft: membership !== "normal" ? "6%" : 0,
                              width: "90%",
                            }
                          : alignment === "start"
                          ? {
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: "22%",
                              paddingLeft: membership !== "normal" ? "12%" : 0,
                              width: "90%",
                            }
                          : {}
                      }
                    >
                      <p
                        style={{
                          fontFamily: userfont
                            ? userfont
                            : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                          // fontStyle: "normal",

                          fontWeight: 600,
                          fontSize: 24,
                          margin: 0,
                          wordBreak: "break-word",
                        }}
                      >
                        {name}
                      </p>
                      {membership !== "normal" ? (
                        <img
                          onContextMenu={(e) => e.preventDefault()}
                          src={verify}
                          alt="Verify"
                          style={{
                            width: 20,
                            height: 20,
                            paddingTop: "0.5%",
                            marginLeft: "2%",
                          }}
                        />
                      ) : null}
                    </div>
                    <p
                      style={
                        alignment === "center"
                          ? {
                              textAlign: "center",
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 600,
                              // fontStyle: "normal",
                              marginTop: 1,
                              marginBottom: 0,
                              fontSize: 17,
                              wordBreak: "break-word",
                              // lineHeight: 1.43
                            }
                          : alignment === "start"
                          ? {
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 600,
                              // fontStyle: "normal",
                              marginTop: 1,
                              marginBottom: 0,
                              fontSize: 17,
                              wordBreak: "break-word",
                              paddingLeft: membership !== "normal" ? "12%" : 0,
                              // lineHeight: 1.43
                            }
                          : {}
                      }
                    >
                      {job ? job : null} {job && company ? "at" : null}{" "}
                      {company ? company : null}
                    </p>
                    <p
                      style={
                        alignment === "center"
                          ? {
                              textAlign: "center",
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 400,
                              // fontStyle: "normal",
                              marginTop: 4,
                              fontSize: 16,
                              wordBreak: "break-word",
                            }
                          : alignment === "start"
                          ? {
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 400,
                              // fontStyle: "normal",
                              marginTop: 4,
                              fontSize: 16,
                              wordBreak: "break-word",
                              paddingLeft: membership !== "normal" ? "12%" : 0,
                            }
                          : {}
                      }
                    >
                      {add}
                    </p>
                    <p
                      style={
                        alignment === "center"
                          ? {
                              textAlign: "center",
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 400,
                              fontStyle: "normal",
                              marginTop: 1,
                              fontSize: 16,
                              marginLeft: 10,
                              marginRight: 10,
                              marginBottom: 20,
                              wordBreak: "break-word",
                            }
                          : alignment === "start"
                          ? {
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 400,
                              fontStyle: "normal",
                              marginTop: 1,
                              fontSize: 16,
                              marginBottom: 20,
                              paddingLeft: membership !== "normal" ? "12%" : 0,
                              wordBreak: "break-word",
                            }
                          : {}
                      }
                    >
                      {bio}
                    </p>
                  </div>
                )}
              </div>
            )}

            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                display: "flex",
                marginTop: "2%",
              }}
            >
              <button
                style={{
                  backgroundColor: linkColor
                    ? linkColor
                    : color === "" || color === "white"
                    ? "black"
                    : color,
                  paddingLeft: "15%",
                  paddingRight: "15%",
                  borderRadius: 50,
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px ",
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                onClick={() => {
                  param1 ? (param1 === "null" ? save() : connectMe()) : save();
                }}
                disabled={loading}
              >
                {loading ? (
                  <div className="spinner"></div>
                ) : (
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontFamily: userfont
                        ? userfont
                        : "Roboto, Helvetica, Arial, sans-serif",
                      fontSize: 19,
                      wordBreak: "break-word",
                    }}
                  >
                    {param1
                      ? param1 === "null"
                        ? "Save Contact"
                        : "Connect With Me"
                      : "Save Contact"}
                  </p>
                )}
              </button>
              {/* <FontAwesomeIcon
          style={{ marginLeft: "3%" }}
          icon={faUserPlus}
          size="2x"
          color={color === "" || color === "white" ? "black" : color}
        /> */}
            </div>
            <div
              style={{
                // backgroundColor: "white",
                margin: "8.09%",
                flexDirection: "row",
                justifyContent: "space-around",
                flexFlow:
                  alignment === "start" || alignment === "portrait"
                    ? "column"
                    : "wrap",
                alignItem: "center",
                display: "flex",
                boxSizing: "border-box",
              }}
            >
              {allData &&
                Array.isArray(allData) &&
                allData.length > 0 &&
                allData.map((item, index) =>
                  item.check ? (
                    item.highlight &&
                    alignment !== "start" &&
                    alignment !== "portrait" ? (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: "2%",
                          width: "100%",
                          padding: "3%",
                          backgroundColor: "#F5F5F4",
                          borderRadius: 14,
                        }}
                      >
                        <button
                          style={{
                            shadowColor: "rgba(0, 0, 0, 0.8)", // Shadow color
                            shadowOpacity: 0.6, // Shadow opacity
                            shadowOffset: { width: 2, height: 2 }, // Shadow offset
                            shadowRadius: 4, // Shadow radius
                            elevation: 4, // Android shadow elevation
                            borderRadius: 12,
                            marginBottom: 0,
                            backgroundColor: "transparent",
                            padding: 0,
                            paddingRight: "2%",
                          }}
                          onClick={() => {
                            if (
                              item.name === "Numbers" ||
                              item.name === "Number"
                            ) {
                              openMessage("sms", item.value);
                              tap(number, item.value, "numbers");
                            } else if (
                              item.name === "Whatsapp" ||
                              item.name === "WhatsApp"
                            ) {
                              window.open(
                                `whatsapp://send?phone=${item.value}`,
                                "_self"
                              );
                              tap(whatsapp, item.value, "whatsapp");
                            } else if (
                              item.name === "Facetime" ||
                              item.name === "FaceTime"
                            ) {
                              tap(facetime, item.value, "facetime");
                              window.open(
                                `facetime://tel:${item.value}`,
                                "_self"
                              );
                            } else if (item.name === "Wechat") {
                              tap(wechat, item.value, "wechat");
                              navigator.clipboard.writeText(item.value);
                            } else if (item.name === "Linktree") {
                              tap(linktree, item.value, "linktree");
                              window.open(item.value, "_self");
                            } else if (item.name === "Twitch") {
                              tap(twitch, item.value, "twitch");
                              window.open(
                                "https://twitch.tv/" + item.value,
                                "_self"
                              );
                            } else if (item.name === "Zelle") {
                              tap(zelle, item.value, "zelle");
                              navigator.clipboard.writeText(
                                item.value,
                                "_self"
                              );
                            } else if (item.name === "Zillow") {
                              tap(zillow, item.value, "zillow");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Cash" ||
                              item.name === "Cash App"
                            ) {
                              tap(cash, item.value, "cash");
                              window.open(
                                "https://cash.app/$" + item.value,
                                "_self"
                              );
                            } else if (item.name === "Venmo") {
                              tap(venmo, item.value, "venmo");
                              window.open(
                                "https://venmo.com/" + item.value,
                                "_self"
                              );
                            } else if (item.name === "Square") {
                              tap(square, item.value, "square");
                              window.open(item.value, "_self");
                            } else if (item.name === "Twitter") {
                              tap(twitter, item.value, "twitter");
                              window.open(
                                "https://twitter.com/" + item.value,
                                "_self"
                              );
                            } else if (item.name === "Clubhouse") {
                              tap(clubhouse, item.value, "clubhouse");
                              window.open(
                                "https://clubhouse.com/@" + item.value,
                                "_self"
                              );
                            } else if (item.name === "Calendly") {
                              tap(calendly, item.value, "calendly");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Soundcloud" ||
                              item.name === "SoundCloud"
                            ) {
                              tap(soundcloud, item.value, "soundcloud");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Customlink" ||
                              item.name === "CustomLink"
                            ) {
                              tap(customLink, item.value, "customLink");
                              window.open(item.value, "_self");
                            } else if (item.name === "Paypal") {
                              tap(paypal, item.value, "paypal");
                              window.open(item.value, "_self");
                            } else if (item.name === "Yelp") {
                              tap(yelp, item.value, "yelp");
                              window.open(item.value, "_self");
                            } else if (item.name === "Spotify") {
                              tap(spotify, item.value, "spotify");
                              window.open(item.value, "_self");
                            } else if (item.name === "Opensea") {
                              tap(opensea, item.value, "opensea");
                              window.open(item.value, "_self");
                            } else if (item.name === "Telegram") {
                              tap(telegram, item.value, "telegram");
                              window.open(
                                "https://t.me/" + item.value,
                                "_self"
                              );
                            } else if (item.name === "Discord") {
                              tap(discord, item.value, "discord");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Applemusic" ||
                              item.name === "Apple Music"
                            ) {
                              tap(applemusic, item.value, "applemusic");
                              window.open(item.value, "_self");
                            } else if (item.name === "Reviews") {
                              tap(reviews, item.value, "reviews");
                              window.open(item.value, "_self");
                            } else if (item.name === "Pinterest") {
                              tap(pinterest, item.value, "pinterest");
                              window.open(
                                "https://pinterest.com/" + item.value,
                                "_self"
                              );
                            } else if (
                              item.name === "Hoobe" ||
                              item.name === "hoo.be"
                            ) {
                              tap(hoobe, item.value, "hoobe");
                              window.open(item.value, "_self");
                            } else if (item.name === "Podcasts") {
                              tap(podcasts, item.value, "podcasts");
                              window.open(item.value, "_self");
                            } else if (item.name === "Poshmark") {
                              tap(poshmark, item.value, "poshmark");
                              window.open(item.value, "_self");
                            } else if (item.name === "Chili Piper") {
                              tap(chiliPiper, item.value, "chiliPiper");
                              window.open(item.value, "_self");
                            } else if (item.name === "Booksy") {
                              tap(booksy, item.value, "booksy");
                              window.open(item.value, "_self");
                            } else if (item.name === "Etsy") {
                              tap(etsy, item.value, "etsy");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Applink" ||
                              item.name === "App Store"
                            ) {
                              tap(appLink, item.value, "appLink");
                              window.open(item.value, "_self");
                            } else if (item.name === "Facebook") {
                              tap(facebook, item.value, "facebook");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Linkedin" ||
                              item.name === "LinkedIn"
                            ) {
                              tap(linkedin, item.value, "linkedin");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Youtube" ||
                              item.name === "YouTube"
                            ) {
                              tap(youtube, item.value, "youtube");
                              window.open(item.value, "_self");
                            } else if (item.name === "Website") {
                              tap(website, item.value, "website");
                              window.open(item.value, "_self");
                            } else if (item.name === "Emails") {
                              tap(email, item.value, "emails");
                              openMessage("mailto", item.value);
                            } else if (item.name === "File") {
                              tap(file, item.value, "file");
                              window.open(item.value, "_self");
                            } else if (item.name === "Call") {
                              tap(call, item.value, "call");
                              openMessage("tel", item.value);
                            } else if (item.name === "Instagram") {
                              tap(instagram, item.value, "instagram");
                              window.open(
                                `https://www.instagram.com/${item.value}`,
                                "_self"
                              );
                            } else if (
                              item.name === "Tiktok" ||
                              item.name === "TikTok"
                            ) {
                              tap(tiktok, item.value, "tiktok");
                              window.open(
                                "https://tiktok.com/@" + item.value,
                                "_self"
                              );
                            } else if (
                              item.name === "Snapchat" ||
                              item.name === "SnapChat"
                            ) {
                              tap(snapchat, item.value, "snapchat");
                              window.open(
                                "https://snapchat.com/add/" + item.value,
                                "_self"
                              );
                            } else if (
                              item.name === "Addresses" ||
                              item.name === "Address"
                            ) {
                              tap(address, item.value, "addresses");
                              window.open(
                                `https://www.google.com/maps/search/?api=1&query=${item.value}`,
                                "_self"
                              );
                            } else if (item.name === "Contact") {
                              contact_card();
                            } else if (item.name === "Trustpilot") {
                              tap(trustpilot, item.value, "trustpilot");
                              window.open(item.value, "_self");
                            }
                          }}
                        >
                          <img
                            onContextMenu={(e) => e.preventDefault()}
                            src={
                              item.image.includes("https")
                                ? item.image
                                : getImageSource(item.image)
                            }
                            alt="message"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: 20,
                              boxShadow:
                                "0px 4px 5.84px rgba(128, 128, 128, 0.35)",
                              objectFit: "cover",
                            }}
                          />
                        </button>
                        <div style={{ marginHorizontal: "5%", width: "80%" }}>
                          <p
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: 18,
                              fontFamily: userfont
                                ? userfont
                                : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                              marginBottom: 0,
                              marginTop: item.description ? "5%" : 0,
                            }}
                          >
                            {item.title === "Numbers"
                              ? "Number"
                              : item.title === "Applink"
                              ? "App Store"
                              : item.title === "Emails"
                              ? "Email"
                              : item.title === "File"
                              ? item.title
                              : item.title === "Addresses"
                              ? "Address"
                              : item.title
                              ? item.title
                              : item.name}
                          </p>

                          {item.description ? (
                            <p
                              style={{
                                color: "black",
                                fontSize: 12,
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 400,
                                marginTop: 4,
                                marginBottom: 4,
                                // textAlign: "justify",
                              }}
                            >
                              {item.description}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    ) : item.name === "Expanded" ||
                      item.name === "Expandable Text" ? (
                      <button
                        key={index}
                        style={{
                          // backgroundColor: expandedIndex === index ? "white" : "grey",
                          width: "90%",
                          borderColor: "#EDEDEC",
                          // borderWidth: 1,
                          // borderStyle: "solid",
                          padding: 0,
                          margin: "4%",
                          height: expandedIndex === index ? "auto" : "",
                        }}
                        onClick={() => handleExpand(index)}
                      >
                        <div>
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              backgroundColor: "#EDEDEC",
                              paddingLeft: "5%",
                              paddingRight: "5%",
                            }}
                          >
                            <p
                              style={{
                                color: "black",
                                fontFamily: userfont
                                  ? userfont
                                  : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: "bold",
                                fontSize: 16,
                                // textAlign: "justify",
                                wordWrap: "break-word",
                              }}
                            >
                              {item.headerText}
                            </p>
                            <FontAwesomeIcon
                              icon={
                                expandedIndex === index
                                  ? faChevronUp
                                  : faChevronDown
                              }
                              size="1x"
                              color="black"
                            />
                          </div>
                          {expandedIndex === index && (
                            <div
                              style={{
                                marginVertical: "2%",
                                display: "flex",
                                // justifyContent: "flex-start",
                                // alignItems: "center",
                                backgroundColor: "white",
                                paddingLeft: "5%",
                                paddingRight: "5%",
                              }}
                            >
                              <p
                                style={{
                                  color: "black",
                                  fontFamily: userfont
                                    ? userfont
                                    : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                                  // textAlign: "justify",
                                  wordWrap: "break-word",
                                  fontSize: 14,
                                }}
                              >
                                {item.bodyText}
                              </p>
                            </div>
                          )}
                        </div>
                      </button>
                    ) : item.name === "Embedded" ||
                      item.name === "Embedded Video" ? (
                      <div
                        style={{
                          borderRadius: 20,
                          overflow: "hidden",
                          width: "100%",
                          height: 200,
                        }}
                      >
                        <ReactPlayer
                          width={"100%"}
                          height={"100%"}
                          url={`https://www.youtube.com/watch?v=${item.value}`}
                        />
                      </div>
                    ) : item.name === "Header" ||
                      item.name === "Header Text" ? (
                      <div
                        key={index}
                        style={{
                          marginBottom: "2%",
                          marginLeft:
                            alignment === "portrait" || alignment === "start"
                              ? "3%"
                              : "10%",
                          marginRight: "10%",
                          width: "100%",
                          alignItems: alignment === "portrait" || alignment === "start"
                              ? "flex-start"
                              : "center",
                          justifyContent: alignment === "portrait" || alignment === "start"
                              ? "flex-start"
                              : "center",
                          display: "flex",
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: 18,
                            fontFamily: userfont
                              ? userfont
                              : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                            // textAlign: "justify",
                          }}
                        >
                          {item.value}
                        </p>
                      </div>
                    ) : (
                      <div
                        key={index}
                        style={
                          alignment === "center"
                            ? {
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "2%",
                              }
                            : alignment === "start"
                            ? {
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "2%",
                              }
                            : {
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "2%",
                              }
                        }
                      >
                        <button
                          style={{
                            shadowColor: "rgba(0, 0, 0, 0.8)", // Shadow color
                            shadowOpacity: 0.6, // Shadow opacity
                            shadowOffset: { width: 2, height: 2 }, // Shadow offset
                            shadowRadius: 4, // Shadow radius
                            elevation: 4, // Android shadow elevation
                            borderRadius: 12,
                            marginBottom: 0,
                            backgroundColor: "transparent",
                            paddingBottom: 0,
                          }}
                          onClick={() => {
                            if (
                              item.name === "Numbers" ||
                              item.name === "Number"
                            ) {
                              openMessage("sms", item.value);
                              tap(number, item.value, "numbers");
                            } else if (
                              item.name === "Whatsapp" ||
                              item.name === "WhatsApp"
                            ) {
                              window.open(
                                `whatsapp://send?phone=${item.value}`,
                                "_self"
                              );
                              tap(whatsapp, item.value, "whatsapp");
                            } else if (
                              item.name === "Facetime" ||
                              item.name === "FaceTime"
                            ) {
                              tap(facetime, item.value, "facetime");
                              window.open(
                                `facetime://tel:${item.value}`,
                                "_self"
                              );
                            } else if (item.name === "Wechat") {
                              tap(wechat, item.value, "wechat");
                              navigator.clipboard.writeText(item.value);
                            } else if (item.name === "Linktree") {
                              tap(linktree, item.value, "linktree");
                              window.open(item.value, "_self");
                            } else if (item.name === "Twitch") {
                              tap(twitch, item.value, "twitch");
                              window.open(
                                "https://twitch.tv/" + item.value,
                                "_self"
                              );
                            } else if (item.name === "Zelle") {
                              tap(zelle, item.value, "zelle");
                              navigator.clipboard.writeText(
                                item.value,
                                "_self"
                              );
                            } else if (item.name === "Zillow") {
                              tap(zillow, item.value, "zillow");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Cash" ||
                              item.name === "Cash App"
                            ) {
                              tap(cash, item.value, "cash");
                              window.open(
                                "https://cash.app/$" + item.value,
                                "_self"
                              );
                            } else if (item.name === "Venmo") {
                              tap(venmo, item.value, "venmo");
                              window.open(
                                "https://venmo.com/" + item.value,
                                "_self"
                              );
                            } else if (item.name === "Square") {
                              tap(square, item.value, "square");
                              window.open(item.value, "_self");
                            } else if (item.name === "Twitter") {
                              tap(twitter, item.value, "twitter");
                              window.open(
                                "https://twitter.com/" + item.value,
                                "_self"
                              );
                            } else if (item.name === "Clubhouse") {
                              tap(clubhouse, item.value, "clubhouse");
                              window.open(
                                "https://clubhouse.com/@" + item.value,
                                "_self"
                              );
                            } else if (item.name === "Calendly") {
                              tap(calendly, item.value, "calendly");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Soundcloud" ||
                              item.name === "SoundCloud"
                            ) {
                              tap(soundcloud, item.value, "soundcloud");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Customlink" ||
                              item.name === "CustomLink"
                            ) {
                              tap(customLink, item.value, "customLink");
                              window.open(item.value, "_self");
                            } else if (item.name === "Paypal") {
                              tap(paypal, item.value, "paypal");
                              window.open(item.value, "_self");
                            } else if (item.name === "Yelp") {
                              tap(yelp, item.value, "yelp");
                              window.open(item.value, "_self");
                            } else if (item.name === "Spotify") {
                              tap(spotify, item.value, "spotify");
                              window.open(item.value, "_self");
                            } else if (item.name === "Opensea") {
                              tap(opensea, item.value, "opensea");
                              window.open(item.value, "_self");
                            } else if (item.name === "Telegram") {
                              tap(telegram, item.value, "telegram");
                              window.open(
                                "https://t.me/" + item.value,
                                "_self"
                              );
                            } else if (item.name === "Discord") {
                              tap(discord, item.value, "discord");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Applemusic" ||
                              item.name === "Apple Music"
                            ) {
                              tap(applemusic, item.value, "applemusic");
                              window.open(item.value, "_self");
                            } else if (item.name === "Reviews") {
                              tap(reviews, item.value, "reviews");
                              window.open(item.value, "_self");
                            } else if (item.name === "Pinterest") {
                              tap(pinterest, item.value, "pinterest");
                              window.open(
                                "https://pinterest.com/" + item.value,
                                "_self"
                              );
                            } else if (
                              item.name === "Hoobe" ||
                              item.name === "hoo.be"
                            ) {
                              tap(hoobe, item.value, "hoobe");
                              window.open(item.value, "_self");
                            } else if (item.name === "Podcasts") {
                              tap(podcasts, item.value, "podcasts");
                              window.open(item.value, "_self");
                            } else if (item.name === "Poshmark") {
                              tap(poshmark, item.value, "poshmark");
                              window.open(item.value, "_self");
                            } else if (item.name === "Chili Piper") {
                              tap(chiliPiper, item.value, "chiliPiper");
                              window.open(item.value, "_self");
                            } else if (item.name === "Booksy") {
                              tap(booksy, item.value, "booksy");
                              window.open(item.value, "_self");
                            } else if (item.name === "Etsy") {
                              tap(etsy, item.value, "etsy");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Applink" ||
                              item.name === "App Store"
                            ) {
                              tap(appLink, item.value, "appLink");
                              window.open(item.value, "_self");
                            } else if (item.name === "Facebook") {
                              tap(facebook, item.value, "facebook");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Linkedin" ||
                              item.name === "LinkedIn"
                            ) {
                              tap(linkedin, item.value, "linkedin");
                              window.open(item.value, "_self");
                            } else if (
                              item.name === "Youtube" ||
                              item.name === "YouTube"
                            ) {
                              tap(youtube, item.value, "youtube");
                              window.open(item.value, "_self");
                            } else if (item.name === "Website") {
                              tap(website, item.value, "website");
                              window.open(item.value, "_self");
                            } else if (item.name === "Emails") {
                              tap(email, item.value, "emails");
                              openMessage("mailto", item.value);
                            } else if (item.name === "File") {
                              tap(file, item.value, "file");
                              window.open(item.value, "_self");
                            } else if (item.name === "Call") {
                              tap(call, item.value, "call");
                              openMessage("tel", item.value);
                            } else if (item.name === "Instagram") {
                              tap(instagram, item.value, "instagram");
                              window.open(
                                `https://www.instagram.com/${item.value}`,
                                "_self"
                              );
                            } else if (
                              item.name === "Tiktok" ||
                              item.name === "TikTok"
                            ) {
                              tap(tiktok, item.value, "tiktok");
                              window.open(
                                "https://tiktok.com/@" + item.value,
                                "_self"
                              );
                            } else if (
                              item.name === "Snapchat" ||
                              item.name === "SnapChat"
                            ) {
                              tap(snapchat, item.value, "snapchat");
                              window.open(
                                "https://snapchat.com/add/" + item.value,
                                "_self"
                              );
                            } else if (
                              item.name === "Addresses" ||
                              item.name === "Address"
                            ) {
                              tap(address, item.value, "addresses");
                              window.open(
                                `https://www.google.com/maps/search/?api=1&query=${item.value}`,
                                "_self"
                              );
                            } else if (item.name === "Contact") {
                              contact_card();
                            } else if (item.name === "Trustpilot") {
                              tap(trustpilot, item.value, "trustpilot");
                              window.open(item.value, "_self");
                            }
                          }}
                        >
                          <img
                            onContextMenu={(e) => e.preventDefault()}
                            src={
                              item.image.includes("https")
                                ? item.image
                                : getImageSource(item.image)
                            }
                            alt="message"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: 20,
                              boxShadow:
                                "0px 4px 5.84px rgba(128, 128, 128, 0.35)",
                              objectFit: "cover",
                            }}
                          />
                        </button>
                        <p
                          style={{
                            marginTop: 5,
                            color: "black",
                            textAlign: alignment === "start" ? "" : "center",
                            fontSize: 11,
                            fontFamily: userfont
                              ? userfont
                              : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                            fontWeight: 400,
                            fontStyle: "normal",
                            // paddingLeft: alignment == "start" ? "9.5%" : 0,
                          }}
                        >
                          {item.title === "Numbers"
                            ? "Number"
                            : item.title === "Applink"
                            ? "App Store"
                            : item.title === "Emails"
                            ? "Email"
                            : item.title === "File"
                            ? truncateTextAfterWords(item.title, 15)
                            : item.title === "Addresses"
                            ? "Address"
                            : item.title
                            ? truncateTextAfterWords(item.title, 15)
                            : item.name}
                        </p>
                      </div>
                    )
                  ) : null
                )}
            </div>
            <div
              style={{
                height: 100,
                // background: color
                //   ? `linear-gradient(to top, ${color}, white)`
                //   : "white",
                // opacity: color ? 0.4 : 1,
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Preview;
